'use strict'


angular.module('cb.enquiry', [])
	.controller('EnquiryDetailsController', ["$rootScope", "$window", "$scope", "$anchorScroll", "$location", "$modal", "$routeParams", "$q", "LeadProfileService", "NotificationService", "PickListService", "StaffService", "LeadProviderService", "MaintenanceService", "EnquiryModalService", "IpadService", "LetterGenerationService", "AppointmentService", "QuoteService", "PricingAppService", function ($rootScope, $window, $scope, $anchorScroll, $location, $modal, $routeParams, $q, LeadProfileService, NotificationService, PickListService, StaffService, LeadProviderService, MaintenanceService, EnquiryModalService, IpadService, LetterGenerationService, AppointmentService, QuoteService, PricingAppService) {

		$scope.init = function () {
			$scope.loadEnquiry();
			// Blank - false do not show buttons.
			// Has SID - true show buttons.
			$scope.fileButtonsEnabled = $rootScope.SynoSid != '' && $rootScope.SynoSid != 'NoLogin';
			$scope.fileSpinnerShow = $rootScope.SynoSid != 'NoLogin' && !$scope.fileButtonsEnabled;
			if ($rootScope.SynoSid != '')
				$scope.fileSpinnerShow = false;
			$scope.IsEmailingQuote = false;
			$scope.OrderDetailLine = [];
			//getOrderLine(false, false);
			$scope.required = "require";
			$scope.canDeleteIssueDate = $rootScope.User.Roles.filter(r => r.Name == 'Super Administrator').length > 0 ? true : false;
			if ($scope.canDeleteIssueDate)
				$scope.required = "";

			$scope.isOnMobile = window.mobileAndTabletcheck();
			$scope.isOnlyFitterUser = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Fitter').length > 0) ? true : false;
			$scope.IsUserContractOrManager = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Contracts' ||
				r.Name == 'Operations Manager' ||
				r.Name == 'Sales Manager' ||
				r.Name == 'Field Manager' ||
				r.Name == 'Area Manager').length >
				0)
				? true
				: false;
			$scope.isUserSalesPerson =
				($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0) ? true : false;
			$scope.isUserAdmin = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'System Administrator').length > 0) ? true : false;
			$scope.canUserRemoveAppointments = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Contracts' ||
				r.Name == 'Operations Manager' ||
				r.Name == 'Sales Manager' ||
				r.Name == 'Field Manager' ||
				r.Name == 'System Administrator').length >
				0)
				? true
				: false;

			$scope.canConfirmedSoldDateBeOverridden = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(
				r => r.Name == 'Contracts' ||
					r.Name == 'Operations Manager' ||
					r.Name == 'Sales Manager' ||
					r.Name == 'Sales Desk' ||
					r.Name == 'Field Manager' ||
					r.Name == 'Fitter' ||
					r.Name == 'Area Manager' ||
					r.Name == 'Accounts' ||
					r.Name == 'Marketing' ||
					r.Name == 'Partnership' ||
					r.Name == 'System Administrator').length >
				0)
				? true
				: false;

			$scope.Hidden = {
				SecondCustomer: false,
				InstallAddress: false,
				ContactAddress: false,
				ContactInformation: false,
				AlternativeContact: false,
				LeadSource: false,
				SourceDetails: false,
				SiteDetails: false,
				AccessDetails: false,
				ActionInfo: false,
				ContactEvents: false,
				OtherCompanyQuotes: false,
				SiteAccessDetails: false
			};

			$scope.ContactEvents = false;
			$rootScope.NumberOfFiles = 0;
			$scope.quotePriceChanged = false;

			// Listen for the pricingAppModalClosed event, then refresh Enquiry
			$rootScope.$on('pricingAppModalClosed', function () {
				$scope.viewEnquiry();
			});
		};

		// Synology logged in, show buttons.
		$scope.$on("SIDSet", function (evt, data) {
			$scope.fileSpinnerShow = false;
			if (data != undefined) {
				fetchCloudFileNumber();
				$scope.fileButtonsEnabled = true;
			}
		});

		function getQuoteLines(created, sortGuarantee) {

			$scope.getQuoteLinePromise = ($rootScope.onLineFlag) ? QuoteService.getQuoteLines($scope.enquiry.SystemId) :
				IpadService.getOrderlines($scope.enquiry.SystemId);
			$scope.getQuoteLinePromise.then(function (output) {
				$scope.LeadProfileQuoteLine = ($rootScope.onLineFlag) ? output.data.Quotelines : output;
				$scope.LeadProfileQuoteLine.map((m) => {
					m.Product = $scope.picklist.find((x) => {
						return (x.Id == m.Product)
					});
					return m;
				});

				// Data required for PricingApp
				$scope.LeadProfileQuoteLineAddOns = output.data.QuotelineAddOns;
				$scope.LeadProfileQuoteAdditionalCharges = output.data.QuoteAdditionalCharges;
				$scope.LeadProfileQuoteModifiers = output.data.QuoteModifiers;

				$scope.total = $scope.GetTotal($scope.LeadProfileQuoteLine);
				if ($scope.LeadProfileQuoteLine.length > 0) {
					$scope.DraftContractOptions = [];
					var optionCheck = 0;
					for (var i = 0; i < $scope.LeadProfileQuoteLine.length; i++) {
						if ($scope.LeadProfileQuoteLine[i].Rails == $scope.CustomPickListId)
							$scope.UnhideCustomRails = true;

						if ($scope.LeadProfileQuoteLine[i].qty > 0 && $scope.LeadProfileQuoteLine[i].Option != optionCheck) {
							optionCheck = $scope.LeadProfileQuoteLine[i].Option;
							$scope.DraftContractOptions.push({ "id": optionCheck, "name": "Option " + optionCheck });
						}
					}

					$scope.isUserPricingApp = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Pricing').length > 0) ? true : false;
					$scope.isQuotePricingApp = $scope.LeadProfileQuoteLine[0].FabricDetail === 'P';

					// Sort and remove duplicates.
					$scope.DraftContractOptions = $scope.DraftContractOptions.sort($scope.rankingSorter("name", "id"));
					var ids = {};
					$scope.DraftContractOptions = $scope.DraftContractOptions.filter(function (option) {
						var ind = option.name + '_' + option.id;
						if (!ids[ind]) {
							ids[ind] = true;
							return true;
						}
						return false;
					});
				}
				if (sortGuarantee)
					saveGuaranteeLevelForOrder($scope.enquiry.SystemId);
			}, function (error) {
				NotificationService.alert('Order Line Error', 'There was an error getting details of the sale order.', 'error', {});
			});
		};

		$scope.showMoreOptions1 = function () {
			const trs = document.getElementsByClassName("opt1");
			const startWith = 0;
			for (i = startWith; i < trs.length; i++) {
				trs[i].classList.toggle('opt1');
			}
		};

		$scope.showMoreOptions2 = function () {
			const trs = document.getElementsByClassName("opt2");
			const startWith = 0;
			for (i = startWith; i < trs.length; i++) {
				trs[i].classList.toggle('opt2');
			}
		};

		$scope.showMoreOptions3 = function () {
			const trs = document.getElementsByClassName("opt3");
			const startWith = 0;
			for (i = startWith; i < trs.length; i++) {
				trs[i].classList.toggle('opt3');
			}
		};

		$scope.rankingSorter = function (firstKey, secondKey) {
			return function (a, b) {
				if (a[firstKey] < b[firstKey]) {
					return -1;
				} else if (a[firstKey] > b[firstKey]) {
					return 1;
				}
				else {
					if (a[secondKey] < b[secondKey]) {
						return 1;
					} else if (a[secondKey] > b[secondKey]) {
						return -1;
					} else {
						return 0;
					}
				}
			}
		}

		$scope.GetTotal = function (Lines) {
			var total = 0;
			angular.forEach(Lines, function (value, key) {
				total += (value.cost * value.qty);
			});
			return total;
		}

		$scope.toggleHidden = function (key) {
			$scope.Hidden[key] = !$scope.Hidden[key];
		};

		$scope.deleteSampleOrder = function (col) {
			if (confirm("Are you sure you want to delete this SampleOrder?")) {
				$scope.deleteSampleOrderPromise = LeadProfileService.deleteSampleOrder(col.Id, $scope.enquiry.LeadId);
				$scope.deleteSampleOrderPromise.then(function (output) {
					$location.path('/lead-details/' + $scope.enquiry.LeadId);
					$scope.loadEnquiry();
					NotificationService.alert('Sample Order Deleted',
						'The Sample Order was deleted successfully.',
						'success',
						{});
				},
					function (error) {
						NotificationService.alert('Sample Order Delete Error',
							'There was an error deleting.',
							'error',
							{});
					});
			}
		};

		$scope.loadEnquiry = function () {
			$scope.CurrentLocationId = $routeParams.id;
			if ($routeParams.id == "new") {
				$scope.hidden = true;
			}
			else {
				$scope.hidden = false;
			}
			$scope.enquiry = {
				Address: {}
			};
			$scope.originalEnquiry = {
				Address: {}
			};
			$scope.copyEnquiry = {
				Address: {}
			};
			$scope.lNoteToggle = false;
			$scope.NoteClass = 'glyphicon glyphicon-chevron-up';
			$scope.difficultCustomer = false;
			$scope.selectedIndex = 1;
			$scope.NewNote = '';
			$scope.NewUnsubscribeItem = {
				TypeOfContact: null,
				Reason: null,
			}
			$scope.AlternativeContactItem = {
				ContactName: null,
				TypeOfContactata: null,
				ContactData: null
			};
			$scope.ContactEventItem = {
				ContactOutcome: null,
				Notes: null
			};
			$scope.SampleOrder = {
				Product: null,
				Fabric: null,
				RailType: null,
				RailColour: null,
				Notes: null
			};

			$scope.Today = new Date();
			$scope.openPopups = {};
			$scope.disabled = false;
			$scope.PinoleumSampleSelected = false;

			//Get All lead providers
			$scope.getLeadProviders();

			//Get All picklist
			$scope.CollectionPromise = ($rootScope.onLineFlag) ? PickListService.getPickListByPickListName(['Title', 'BestTime', 'mailType', 'propertyType', 'businessType', 'ddmRequirements', 'ddmInterestedIn', 'ContactType', 'leadSource', 'LeadCode', 'CnsAge', 'BlindUse', 'CnsBuilder', 'ddmSiteType', 'ddmConstruction', 'ddmShape', 'CnsAspect', 'ddmGlazing', 'ddmRidgeHeight', 'ddmSiteAccess', 'ddmParking', 'ddmAccessEquipment', 'Qualified', 'LeadProviderFeedback', 'UnsubscribeReason', 'TypeOfContact', 'ddmCompetitorCompany', 'ContactType', "ddmFabric", "ddmProduct", "ddmProductType", "ddmRailColour", "ddmContactType", "ddmRailType", 'altContactType', 'leadCode', 'ContactEventOutcome', 'ddmEnquiryProductType', 'ddmParking', 'ddmSiteAccess', 'ddmAccessEquipment', 'ddmRidgeHeight', 'ddmBraidColour', 'ddmPaymentTypes', 'ddmHardwareType', 'ddmOperation', 'ddmMicro-CableColour', 'ddmHandleColour', 'ddmRemoteColour']) :
				IpadService.getPicklist();
			$scope.CollectionPromise.then(function (output) {
				$scope.picklist = ($rootScope.onLineFlag) ? output.data.PickListEntries : output;
			}, function (error) { });

			//Get Enquiry Handlers
			$scope.GetAllStaffPromise = ($rootScope.onLineFlag) ? StaffService.getAllStaff() : IpadService.getAllStaff();
			$scope.GetAllStaffPromise.then(function (output) {
				$scope.allStaffList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
			}, function (error) {
				NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});

			//Get All Action
			// offline needs adding
			$scope.GetAllActionPromise = ($rootScope.onLineFlag) ? MaintenanceService.getAllActions() : IpadService.getAllActions();
			$scope.GetAllActionPromise.then(function (output) {
				$scope.allActionList = ($rootScope.onLineFlag) ? output.data.ActionList : output;
			}, function (error) {
				NotificationService.alert('Action Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});

			//Get All Status list
			$scope.GetAllStatusPromise = ($rootScope.onLineFlag) ? MaintenanceService.getAllStatuses() : IpadService.getStatusLists();
			$scope.GetAllStatusPromise.then(function (output) {
				$scope.allStatusList = ($rootScope.onLineFlag) ? output.data.StatusList : output;
			}, function (error) {
				NotificationService.alert('Status Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});

			$q.all([$scope.GetAllLeadProviderPromise, $scope.CollectionPromise, $scope.GetAllStaffPromise, $scope.GetAllActionPromise, $scope.GetAllStatusPromise]).then(function () {
				if ($routeParams.id > 0) {
					$scope.viewEnquiry();

				} else {
					var localdata = $routeParams;
					$scope.enquiry.Surname = localdata.Surname;
					$scope.enquiry.Address.Postcode = localdata.Postcode;
					$scope.enquiry.Company = localdata.Company;
					//$scope.save();
					if ($scope.enquiry.Address.Postcode != undefined) {
						//method to find already existing addresses with this postcode. If they exist in the database, it will fill in the basic information about the address.
						$scope.FindExistingAddressPromise = EnquiryModalService.findExistingAddress($scope.enquiry.Address.Postcode);
						$scope.FindExistingAddressPromise.then(function (output) {
							$scope.enquiry.Address.PrimaryStreet = output.data.Address.AddressLine1;
							$scope.enquiry.Address.SecondaryStreet = output.data.Address.AddressLine2;
							$scope.enquiry.Address.AddressLine3 = output.data.Address.AddressLine3;
							$scope.enquiry.Address.Town = output.data.Address.Town;
							$scope.enquiry.Address.County = output.data.Address.County;
						})
					}
				};
				$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Fitter' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;
				$scope.discountPermission = $rootScope.User.Roles.filter(r => r.Name == 'System Administrator').length > 0 ? true : false;
				$scope.getLeadProvider();
			});
		};

		$scope.getLeadProviders = function () {
			$scope.GetAllLeadProviderPromise = ($rootScope.onLineFlag) ? LeadProviderService.getAllLeadProviders() : IpadService.getLeadProviders();
			$scope.GetAllLeadProviderPromise.then(function (output) {
				$scope.leadProviderList = ($rootScope.onLineFlag) ? output.data.LeadProviders : output;
			}, function (error) {
				NotificationService.alert('Lead Provider Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
		};

		$scope.$on('$locationChangeStart', function (event) {
			if ($scope.disabled == false) {
				if (angular.equals($scope.enquiry, $scope.originalEnquiry)) {
				}
				else {
					var answer = confirm("Unsaved data will be lost, are you sure you want to leave this page?")
					if (!answer) {
						event.preventDefault();
					}
				}
			}
		});

		window.onbeforeunload = function (event) {
			if (angular.equals($scope.enquiry, $scope.originalEnquiry)) { }
			else {
				var message = 'Unsaved data will be lost.Are you sure you want to close the page ?';
				if (typeof event == 'undefined') {
					event = window.event;
				}
				if (event) {
					event.returnValue = message;
				}
				return message;
			}
		}

		$scope.getIssuedDate = function () {
			$scope.getIssuedDate = function () {
				return LeadProfileService.getIssuedDate($scope.enquiry.LeadId)
					.then(function (issuedDate) {
						return issuedDate;
					});
			};
		};

		$scope.viewEnquiry = function (appointed) {
			if ($scope.enquiry.LeadId != undefined) {
				$scope.GetEnquiryPromise = ($rootScope.onLineFlag) ? LeadProfileService.getEnquiryById($scope.enquiry.LeadId) : IpadService.getLeadProfile($scope.enquiry.LeadId);
			}
			else {
				$scope.GetEnquiryPromise = ($rootScope.onLineFlag) ? LeadProfileService.getEnquiryById($routeParams.id) : IpadService.getLeadProfile($routeParams.id); // "new" returns 404
			}
			$scope.GetEnquiryPromise.then(function (output) {
				$scope.enquiry = ($rootScope.onLineFlag) ? output.data.LeadDetail : output;
				getQuoteLines(false, false);

				$scope.EnableDisabledQuoteBtn();

				//add salesperson who have left to the enquiry ddm
				if ($scope.enquiry.SalesPersonObj && $scope.allStaffList.filter(e => e.Id === $scope.enquiry.SalesPerson).length == 0) {
					$scope.allStaffList.push({ Id: $scope.enquiry.SalesPersonObj.Id, Email: $scope.enquiry.SalesPersonObj.EmailAddress, FirstName: '[LEFT] ' + $scope.enquiry.SalesPersonObj.FirstName, LastName: $scope.enquiry.SalesPersonObj.LastName, Roles: $scope.enquiry.SalesPersonObj.Roles });
				}
				//if lead provider is present, but not in the lead provider table
				if ($scope.enquiry.LeadProvider != null) {
					if ($scope.leadProviderList.filter(e => e.Id === $scope.enquiry.LeadProvider.Id).length == 0) {
						// Does not contain lead provider- add it as it may be an old/legacy one
						$scope.leadProviderList.push($scope.enquiry.LeadProvider);
						console.log("Adding lead provider");
					}
				}

				if ($rootScope.SynoSid != '') {
					fetchCloudFileNumber();
				}
				$scope.enquiry.SampleOrders.forEach((order) => {
					if (order.Product != 0)
						order.ProductString = $scope.picklist.find(pl => pl.Id == order.Product).PickListEntry;
					else
						order.ProductString = 'Empty';
					if (order.Fabric != 0)
						order.FabricString = $scope.picklist.find(pl => pl.Id == order.Fabric).PickListEntry;
					else
						order.FabricString = 'Empty';
					if (order.RailType != 0)
						order.RailTypeString = $scope.picklist.find(pl => pl.Id == order.RailType).PickListEntry;
					else
						order.RailTypeString = 'Empty';
					if (order.RailColour != 0)
						order.RailColourString = $scope.picklist.find(pl => pl.Id == order.RailColour).PickListEntry;
					else
						order.RailColourString = 'Empty';
					if (order.BraidColour != 0)
						order.BraidColourString = $scope.picklist.find(pl => pl.Id == order.BraidColour).PickListEntry;
					else
						order.BraidColourString = 'Empty';
					$scope.formatSampleDate(order, true);
				});
				getDifficulty();

				if (window.location.href.indexOf('new') > 0) {
					//triggers on new linked enquiry
					$scope.enquiry.ParentLead = $routeParams.id;
					$scope.enquiry.ChangeStatus = $scope.allStatusList.find(data => data.Name == 'PreSales').Id;
					$scope.enquiry.Quoted = null;
					$scope.enquiry.QuotedProduct = null;
					$scope.enquiry.QuotedUse1 = null;
					$scope.enquiry.QuoteElectric1 = null;
					$scope.enquiry.QuotedRC1 = null;
					$scope.enquiry.QuoteUploaded1 = null;
					$scope.enquiry.QuotedPrice = null;
					$scope.enquiry.QuoteDiscount1 = null;
					$scope.enquiry.QuotedProduct2 = null;
					$scope.enquiry.QuotedUse2 = null;
					$scope.enquiry.QuoteElectric2 = null;
					$scope.enquiry.QuotedRC2 = null;
					$scope.enquiry.QuoteUploaded2 = null;
					$scope.enquiry.QuotedPrice2 = null;
					$scope.enquiry.QuoteDiscount2 = null;
					$scope.enquiry.QuotedProduct3 = null;
					$scope.enquiry.QuotedUse3 = null;
					$scope.enquiry.QuoteElectric3 = null;
					$scope.enquiry.QuotedRC3 = null;
					$scope.enquiry.QuoteUploaded3 = null;
					$scope.enquiry.QuotedPrice3 = null;
					$scope.enquiry.QuoteDiscount3 = null;
					$scope.enquiry.TotalQuotedPrice1 = null;
					$scope.enquiry.TotalQuotedPrice2 = null;
					$scope.enquiry.TotalQuotedPrice3 = null;
					$scope.enquiry.SalesPerson = null;
					$scope.enquiry.Issued = null;
					$scope.enquiry.HODoNotCall = null;
					$scope.enquiry.FollowUpCallDue = null;
					$scope.enquiry.FollowUpMailDue = null;
					$scope.enquiry.LeadDate = new moment().format('YYYY-MM-DDTHH:mm:SS'); //todays date
					console.log($scope.enquiry.LeadDate);
					$scope.enquiry.LeadSource = $scope.picklist.find(p => p.PickListEntry == "Existing Customer").Id; //existing customer
					$scope.enquiry.SourceDetail = null;
					$scope.enquiry.LeadAnalysisCode = $routeParams.id; //original lead
					$scope.enquiry.LastUpdated = null;
					$scope.enquiry.SampleOrders = null;
					$scope.enquiry.SampleOrder = null;
					$scope.enquiry.SampleSent = null;
					$scope.enquiry.BrochureSent = null;
					$scope.enquiry.GuaranteeNotes = "";
					$scope.enquiry.ColourNotes = "";
					$scope.enquiry.QuoteRecOption1 = false;
					$scope.enquiry.QuoteRecOption2 = false;
					$scope.enquiry.QuoteRecOption3 = false;

					$scope.save();
				}

				//if user has been bounced back to leads by created sale order, open up deposit modal.
				if (window.location.href.indexOf('deposit') > 0) {
					if ($scope.enquiry.OrderId > 0) {
						$scope.openCreatePaymentModal($scope.enquiry.OrderId, 'Deposit');
					}
					else {
						NotificationService.alert('Deposit Error', 'Could not find the new order Id to create a new deposit.', 'error', {});
					}
				}

				//GET LATEST NOTE HERE
				$scope.gettingNoteHistoryPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getNoteHistory($scope.enquiry.SystemId) : IpadService.getNotesOrderId($scope.enquiry.SystemId);
				$scope.gettingNoteHistoryPromise.then(function (output) {
					if (output.data.Notes && output.data.Notes.length)
						$scope.LatestNote = output.data.Notes[0];
				}, function (error) {
					NotificationService.alert('Enquiry Error', 'There was an error issuing the enquiry.', 'error', {});
				});

				angular.copy($scope.enquiry, $scope.originalEnquiry);
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Enquiry Error', 'There was an error retriving the enquiry.', 'error', {});
			});
		};
		$scope.GDPRConfirm = function () {
			$scope.enquiry.GDPRPermission = confirm('we only use your email to contact you regarding your quote, and to send any special offers. We don’t pass onto other companies. Is that OK?')
		};

		function getDifficulty() {
			$scope.gettingDifficultyPromise = ($rootScope.onLineFlag) ? LeadProfileService.getDifficulty($scope.enquiry.LeadId) : IpadService.getLeadDifficulty($scope.enquiry.LeadId);
			$scope.gettingDifficultyPromise.then(data => {
				$scope.difficultCustomer = data.data.Difficult;
				if ($scope.difficultCustomer) {
					$scope.DifficultCustomerStyle = {
						"color": "#e75a5a",
					}
				} else {
					$scope.DifficultCustomerStyle = {
						"color": "#7dcc93",
					}
				}
			}, err => {
				NotificationService.alert('Difficulty Error', 'There was an error retriving the difficulty of this customer.', 'error', {});
			});
		};

		$scope.CheckPinoleumSample = function () {
			var checkVal = $scope.picklist.find(x => x.Id == $scope.SampleOrder.Product);
			if (checkVal != undefined) {
				if (checkVal.PickListEntry.includes("Pinoleum"))
					$scope.PinoleumSampleSelected = true;
				else {
					$scope.PinoleumSampleSelected = false;
					$scope.SampleOrder.BraidColour = null;
				}
			}
			else {
				$scope.PinoleumSampleSelected = false;
				$scope.SampleOrder.BraidColour = null;
			}
			return $scope.PinoleumSampleSelected;
		}

		$scope.toggleDifficult = function () {
			$scope.settingDifficultyPromise = ($rootScope.onLineFlag) ? LeadProfileService.setDifficulty({
				LeadId: $scope.enquiry.LeadId
			}) : IpadService.toggleLeadDifficulty($scope.enquiry.LeadId);
			$scope.settingDifficultyPromise.then(success => {
				NotificationService.alert('Customer Type Changed', "", 'success', {});
				getDifficulty();
			}, err => {
				NotificationService.alert('Customer Type Change Error', '', 'error', {});
			});
		};

		$scope.RemoveUnsubscribeItem = function (Item) {
			var index = $scope.enquiry.UnsubscribeItem.indexOf(Item);
			$scope.enquiry.UnsubscribeItem.splice(index, 1);

		};

		$scope.AddNewUnsubscribeItem = function (Item) {
			$scope.enquiry.UnsubscribeItem.push(Item);
		};

		//opening and closing of datepickers
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.getLeadProvider = function () {
			$scope.GetLeadProviderPromise = ($rootScope.onLineFlag) ? StaffService.getStaffByRole('Sales') : IpadService.getStaffByRole('Sales');
			$scope.GetLeadProviderPromise.then(function (output) {
				$scope.enquiryHandlerList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
			}, function (error) {
				NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
		};

		$scope.issueLead = function (enquiry) {
			if ($scope.enquiry.LeadType == 'Quote' || $scope.enquiry.LeadType == 'Callback') {
				var data = {
					LeadId: $routeParams.id,
					LeadType: $scope.enquiry.LeadType,
					LeadPostCode: $scope.enquiry.Address.Postcode
				};
				var modalInstance = openModal('views/modal-templates/issue-lead.html', 'IssueLeadModalController', data);
				modalInstance.result.then(function (data) {
					$scope.enquiry.Issued = new Date();
					$scope.loadEnquiry();
				});
			} else {
				NotificationService.alert('Enquiry Type', 'In order to issue this lead, the enquiry type must either be Callback or Appointment.', 'warning', {});
			};
		};

		$scope.enquiryPage = function () {
			$location.path('/enquiry-details/' + $scope.enquiry.LeadId);
		}

		$scope.viewOrder = function () {
			if ($scope.enquiry.OrderId > 0)
				$location.path('/sales-order/' + $scope.enquiry.LeadId);
			else
				$location.path('/create-sales-order/' + $scope.enquiry.LeadId);
		};

		$scope.openQuotesI = function (LeadId, Item) {
			//alert(JSON.stringify(Item));
			$scope.openQuoteLinesModal({
				leadId: LeadId,
				item: Item,
				list: $scope.LeadProfileQuoteLine,
				addons: $scope.LeadProfileQuoteLineAddOns,
				charges: $scope.LeadProfileQuoteAdditionalCharges,
				modifiers: $scope.LeadProfileQuoteModifiers,
				PickList: $scope.picklist,
				enquiry: $scope.enquiry
			})
		};

		$scope.openQuotesIP = function (LeadId, Item) {
			//alert(JSON.stringify(Item));
			$scope.openPricingLinesModal({
				leadId: LeadId,
				item: Item,
				list: $scope.LeadProfileQuoteLine,
				PickList: $scope.picklist,
				enquiry: $scope.enquiry
			})
		};

		$scope.deleteSelectedQuote = function (LeadId, item) {

			if (confirm('Are you sure you want to remove this record?')) {
				var data = {
					OrderLineId: item.LineId
				};

				$scope.RemoveQuoteItemPromise = QuoteService.deleteQuoteLine(data);
				$scope.RemoveQuoteItemPromise.then(function (output) {
					$scope.save(true); // Save the enquiry after saving quote lines
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Quote Line Error', 'There was an error removing the quote item.', 'error', {});
				});
			}
		};

		$scope.openQuoteLinesModal = function (data) {
			var quoteModalInstance = openQuoteModal('views/modal-templates/quote-lines.html', 'QuoteLinesController', data);

			quoteModalInstance.result.then(function (output) {
				$scope.save(true); // Save the enquiry after saving quote lines
				//$scope.viewEnquiry(); // This does "refresh" the lead page after saving, but does not update the "Quoted Price"!
			})

		}

		$scope.openPricingLinesModal = function (data) {
			var quoteModalInstance = openQuoteModal('views/modal-templates/pricing-lines.html', 'PricingLinesController', data);

			quoteModalInstance.result.then(function (output) {
				$scope.save(true); // Save the enquiry after saving quote lines
				//$scope.viewEnquiry(); // This does "refresh" the lead page after saving, but does not update the "Quoted Price"!
			})

		}

		$scope.GenerateEmailContract = function () {
			if ($scope.enquiry.OrderId > 0) {
				if (confirm('This will generate and send a contract to the customer for this order. Please ensure that you only do this if the customer is expecting to receive a new or updated contract. If you are unsure then please check with the Contracts Team. Do you wish to continue?')) {
					//console.log(JSON.stringify($scope.enquiry));
					//console.log(JSON.stringify($scope.enquiry.OrderId));

					$scope.emailingContractPromise = LetterGenerationService.emailContract({ OrderId: $scope.enquiry.OrderId });
					$scope.emailingContractPromise.then(success => {
						NotificationService.alert('Contract Emailed', 'Contract emailed successfully.', 'success', {});
						$scope.getOrderData($routeParams.id);
					}, err => {
						NotificationService.alert('Contract Email Error', 'There was an error when attempting to email this contract.', 'error', {});
					});
				}
			}
			else {
				NotificationService.alert('Contract Email Error', 'Lead has not been converted into an order yet. Contract cannot be generated until an order has been created.', 'error', {});
			}
		};

		$scope.GenerateEmailDraftContract = function () {
			if ($scope.enquiry.OrderId > 0 || $scope.enquiry.SystemId > 0) {
				if (confirm('This will generate and send a draft contract to the customer for this order. Please ensure that you only do this if the customer is expecting to receive a new or updated contract. If you are unsure then please check with the Contracts Team. Do you wish to continue?')) {
					$scope.emailingContractPromise = LetterGenerationService.emailDraftContract({ OrderId: $scope.enquiry.OrderId, LeadId: $scope.enquiry.SystemId, Option: $scope.enquiry.ContractOption.id });
					$scope.emailingContractPromise.then(success => {
						NotificationService.alert('Draft Contract Emailed', 'Draft Contract emailed successfully.', 'success', {});
						$scope.getOrderData($routeParams.id);
					}, err => {
						NotificationService.alert('Draft Contract Email Error', 'There was an error when attempting to email this draft contract.', 'error', {});
					});
				}
			}
			else {
				NotificationService.alert('Draft Contract Email Error', 'Lead has not been converted into an order yet. Draft Contract cannot be generated until an order has been created.', 'error', {});
			}
		};

		$scope.PreviewContract = function () {
			if ($scope.enquiry.OrderId > 0) {
				$scope.previewContractPromise = LetterGenerationService.previewContract({ OrderId: $scope.enquiry.OrderId });
				$scope.previewContractPromise.then(function (output) {
					NotificationService.alert('Contract Preview', 'Contract generated successfully.', 'success', {});
					var blob = new Blob([output.data], { type: 'application/pdf' });
					var blobURL = URL.createObjectURL(blob);
					window.open(blobURL);

					$scope.getOrderData($routeParams.id);
				}, function (error) {
					NotificationService.alert('Contract Preview Error 1', 'There was an error when attempting to preview this contract.', 'error', {});
				});
			}
			else {
				NotificationService.alert('Contract Preview Error', 'Lead has not been converted into an order yet. Contract cannot be generated until an order has been created.', 'error', {});
			}
		};

		$scope.PreviewDraftContract = function () {
			if ($scope.enquiry.OrderId > 0 || $scope.enquiry.SystemId > 0) {
				$scope.previewContractPromise = LetterGenerationService.previewDraftContract({ OrderId: $scope.enquiry.OrderId, LeadId: $scope.enquiry.SystemId, Option: $scope.enquiry.ContractOption.id });
				$scope.previewContractPromise.then(function (output) {
					NotificationService.alert('Draft Contract Preview', 'Draft Contract generated successfully.', 'success', {});
					var blob = new Blob([output.data], { type: 'application/pdf' });
					var blobURL = URL.createObjectURL(blob);
					window.open(blobURL);

					$scope.getOrderData($routeParams.id);
				}, function (error) {
					NotificationService.alert('Draft Contract Preview Error', 'There was an error when attempting to preview this draft contract.', 'error', {});
				});
			}
			else {
				NotificationService.alert('Draft Contract Preview Error', 'Lead has not been converted into an order yet. Draft Contract cannot be generated until an order has been created.', 'error', {});
			}
		};

		$scope.GenerateEmailQuote = function () {
			if (confirm('This will generate and send a quote to the customer for this order. Please ensure that you only do this if the customer is expecting to receive a new or updated quote. If you are unsure then please check with the Quotes Team. Do you wish to continue?')) {
				$scope.emailingQuotePromise = LetterGenerationService.emailQuote({ OrderId: $scope.enquiry.SystemId });
				$scope.emailingQuotePromise.then(success => {
					$scope.enquiry.ChangeStatus = $scope.allStatusList.find(function (item) { return item.Name == 'Quoted: Hot' }).Id;
					$scope.IsEmailingQuote = true;
					$scope.save(true);
					$scope.IsEmailingQuote = false;
					NotificationService.alert('Quote Emailed', 'Quote emailed successfully.', 'success', {});
					$scope.getOrderData($routeParams.id);
				}, err => {
					NotificationService.alert('Quote Email Error', 'There was an error when attempting to email this quote.', 'error', {});
				});
			}
		};

		$scope.PreviewQuote = function () {

			// Ensure notes if altered, are put onto the quote. #22714790
			var data = {
				LeadId: $scope.enquiry.LeadId,
				Notes: $scope.enquiry.QuotationNotes
			};

			$scope.SaveEnquiryPromise = LeadProfileService.updateLeadNotes(data);
			$scope.SaveEnquiryPromise.then(function (output) {

				// Notes saved, show quote preview.
				$scope.previewQuotePromise = LetterGenerationService.previewQuote({ OrderId: $scope.enquiry.SystemId });
				$scope.previewQuotePromise.then(function (output) {
					NotificationService.alert('Quote Preview', 'Quote generated successfully.', 'success', {});
					var blob = new Blob([output.data], { type: 'application/pdf' });
					var blobURL = URL.createObjectURL(blob);
					window.open(blobURL);

					$scope.getOrderData($routeParams.id);
				}, function (error) {
					NotificationService.alert('Quote Preview Error', 'There was an error when attempting to preview this quote.', 'error', {});
				});

			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Enquiry Notes Error', 'There was an error saving the enquiry notes.', 'error', {});
			});

		};

		$scope.viewLead = function (enquiry) {
			var navigate = true;
			//If lead hasn't been issued prompt
			if ($scope.enquiry.Issued == "0001-01-01T00:00:00")
				navigate = !confirm('Lead has not been Issued. Return to Issue Lead?');

			//go to page if required
			if (navigate)
				$location.path('/lead-details/' + $scope.enquiry.LeadId);
		};

		$scope.formatSampleDate = function (col, init) {
			if (col.DateSent == '0001-01-01T00:00:00') {
				col.DateSent = null;
				return;
			};
			var date = moment(col.DateSent).set('hour', 12);
			col.DateSent = date;
			col.DisplayDateSent = date.format('DD/MM/YYYY');
			if (!init)
				$scope.save();
		};

		$scope.save2 = function () {
			// This is used so we can check if an admin has changed the "Issued" date while a non-admin is updating the same record
			if (!$scope.canDeleteIssueDate) {
				// As Non-admin amd Issued date is null, check if an admin has updated "Issued" date in meantime
				$scope.GetIssuedDatePromise = LeadProfileService.getIssuedDate($scope.enquiry.LeadId);
				$scope.GetIssuedDatePromise.then(function (output) {
					var issuedDate = output.data.replace(/['"]/g, '');
					$scope.save(false, issuedDate);
				});
			}
			else {
				$scope.save();
			}
		}

		$scope.save = function (skipNotification = false, issueDate = null) {

			$scope.disabled = true;

			if ($scope.quotePriceChanged) {
				if (confirm("The quoted price has been updated. Do you want to set the status to Quoted: Hot? If not, it will be set to Quoted: returned to HO."))
					$scope.enquiry.ChangeStatus = $scope.allStatusList.find(function (status) {
						return status.Name == "Quoted: Hot";
					}).Id;
				else
					$scope.enquiry.ChangeStatus = $scope.allStatusList.find(function (status) {
						return status.Name == "Quoted: return to HO";
					}).Id;
			}


			if (window.location.href.indexOf('new') > 0) {
				$scope.enquiry.ParentLead = $scope.enquiry.LeadId;
				$scope.enquiry.LeadId = 0;
			};
			if ($scope.enquiry.SourceDetail != undefined) {
				$scope.enquiry.SourceDetail.ParentEntry = $scope.enquiry.LeadSource;
			};
			// set handler to stella if handler is null
			if ($scope.enquiry.EnquiryHandler == undefined || $scope.enquiry.EnquiryHandler == '00000000-0000-0000-0000-000000000000') {
				for (var i = 0; i < $scope.allStaffList.length; i++) {
					if ($scope.allStaffList[i].Email == 'stella@conservatoryblinds.co.uk') {
						$scope.enquiry.EnquiryHandler = $scope.allStaffList[i].Id;
					}
				}
			}

			if ($scope.enquiry.ChangeStatus == undefined && $scope.enquiry.CurrentStatus == undefined) {
				NotificationService.alert('No Status Selected', 'No Status has been selected by the user. Defaulting to PreSale.');
				$scope.enquiry.ChangeStatus = $scope.allStatusList.find(data => data.Name == 'PreSales').Id;
			}

			angular.copy($scope.enquiry, $scope.copyEnquiry)
			var data = {
				LeadDetail: $scope.copyEnquiry
			};

			if ($scope.AlternativeContactItem.ContactName != null && $scope.AlternativeContactItem.TypeOfContactata != null && $scope.AlternativeContactItem.ContactData != null)
				data.LeadDetail.AlternativeContactItems.push($scope.AlternativeContactItem);

			if ($scope.ContactEventItem.ContactOutcome != null)
				data.LeadDetail.ContactEvents.push($scope.ContactEventItem);

			if ($scope.SampleOrder.Product != null || $scope.SampleOrder.Fabric != null || $scope.SampleOrder.RailColour != null)
				data.LeadDetail.SampleOrders.push($scope.SampleOrder);

			if (data.LeadDetail.SampleOrder == '0001-01-01T00:00:00' && data.LeadDetail.SampleOrders != null && data.LeadDetail.SampleOrders.length > 0) {
				var date = data.LeadDetail.SampleOrders[data.LeadDetail.SampleOrders.length - 1].DateSent;
				if (date != '0001-01-01T00:00:00')
					data.LeadDetail.SampleOrder = moment(date);
			};

			//sort out dates when trying to apply BST.
			data.LeadDetail.FollowUpCallDue = window.formatDate(data.LeadDetail.FollowUpCallDue);
			data.LeadDetail.FollowUpMailDue = window.formatDate(data.LeadDetail.FollowUpMailDue);

			if (!$scope.canDeleteIssueDate && data.LeadDetail.Issued == null && issueDate != null) {
				// Update "Issued" date as admin has updated!
				data.LeadDetail.Issued = window.formatDate(issueDate);
			}
			else {
				data.LeadDetail.Issued = window.formatDate(data.LeadDetail.Issued);
			}

			data.LeadDetail.BrochureSent = window.formatDate(data.LeadDetail.BrochureSent);

			if ($scope.IsEmailingQuote) {
				var now = new Date();
				var offset = new Date().getTimezoneOffset();
				now.setMinutes(now.getMinutes() - offset); // adjusted for timezone

				data.LeadDetail.Quoted = now;
			}
			else {
				data.LeadDetail.Quoted = window.formatDate(data.LeadDetail.Quoted);;
			}

			data.LeadDetail.SampleOrder = window.formatDate(data.LeadDetail.SampleOrder);

			$scope.SaveEnquiryPromise = LeadProfileService.updateLead(data);
			$scope.SaveEnquiryPromise.then(function (output) {
				$scope.quotePriceChanged = false;
				$scope.output = output.data.Id;
				$scope.enquiry.LeadId = output.data.Id;
				if (window.location.href.indexOf('new') > 0 && $scope.output != null) {
					$location.path('/enquiry-details/' + $scope.enquiry.LeadId);
					//window.onhashchange = function (event) {
					//	if (event.newURL.indexOf('new') >= 0) {
					//		window.onhashchange = function () { };
					//		//$location.path('/sales-order/' + $scope.CurrentLocationId);
					//		$location.path('/enquiry-details/' + $scope.CurrentLocationId);
					//	};
					//};
				};
				if (window.location.href.indexOf('deposit') > 0) {
					$location.path('/lead-details/' + $scope.enquiry.LeadId);
				}
				if (!skipNotification)
					NotificationService.alert('Enquiry Success', 'Enquiry detail saved successfully.', 'success', {});
				$scope.$emit('StartUpdateKPIs');
				$scope.viewEnquiry();
				$scope.AlternativeContactItem = {
					ContactName: null,
					TypeOfContactata: null,
					ContactData: null
				};
				$scope.ContactEventItem = {
					ContactOutcome: null,
					Notes: null
				};
				$scope.SampleOrder = {
					Product: null,
					Fabric: null,
					RailType: null,
					RailColour: null,
					Notes: null
				};
				if (window.location.href.indexOf('new') > 0) {
				}
				else {
					$scope.disabled = false;
				}
				angular.copy($scope.enquiry, $scope.originalEnquiry);
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Enquiry Error', 'There was an error saving the enquiry.', 'error', {});
				$scope.disabled = false;
			});
		};
	
		//book appointment
		$scope.MakeNewAppointment = function (customerInfo) {
			var Data = {
				Type: 'Enquiry',
				ObjectID: customerInfo.SystemId,
				Ref: customerInfo.LeadId,
				sender: $rootScope.User.FullName(),
				Customer: customerInfo.Forename + ' ' + customerInfo.Surname,
				SelectedRecipient: $scope.enquiry.SalesPerson
			}
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'views/modal-templates/book-appointment.html',
				controller: 'CreateAppointmentModalCtrl',
				resolve: {
					Data: function () {
						return Data;
					}
				}
			});

			modalInstance.result.then(function (output) {
				$scope.enquiry.Appointment = output.Start;
				$scope.enquiry.Appointment2 = output.End;
				$scope.enquiry.ChangeStatus = $scope.allStatusList.find(function (item) { return item.Name == 'Appointed' }).Id;
				$scope.save();
			});
		};

		//memo functions
		$scope.viewMemos = function () {
			$scope.ViewEnquiryMemoPromise = ($rootScope.onLineFlag) ? LeadProfileService.getMemos($scope.enquiry.Id) : IpadService.getMemoByKey($scope.enquiry.Id);
			$scope.ViewEnquiryMemoPromise.then(function (output) {
				NotificationService.alert('Enquiry Success', 'Enquiry issued successfully.', 'success', {});
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Enquiry Error', 'There was an error issuing the enquiry. testing', 'error', {});
			});
		};

		$scope.createMemo = function (enquiry) {
			var modalInstance = openModal('views/modal-templates/add-memo.html', 'SendMemoController', {
				Id: enquiry.LeadId,
				SystemId: enquiry.SystemId,
				Type: "Reminder",
				SentPath: "enquiry-details",
				SentId: enquiry.LeadId,
				Name: ($scope.enquiry.Forename || '') + ' ' + ($scope.enquiry.Surname || '')
			});

			modalInstance.result.then(function () {
				$scope.$emit('StartUpdateKPIs');
			});
		};

		$scope.SendSamplesToFactory = function (enquiry) {
			var modalInstance = openModal('views/modal-templates/add-memo.html', 'SendMemoController', {
				Id: enquiry.LeadId,
				SystemId: enquiry.SystemId,
				Type: "Sample",
				SentPath: "lead-details",
				SentId: enquiry.LeadId,
				Name: ($scope.enquiry.Forename || '') + ' ' + ($scope.enquiry.Surname || ''),
				Samples: $scope.enquiry.SampleOrders
			});

			modalInstance.result.then(function () {
				$scope.$emit('StartUpdateKPIs');
			});
		};

		//modal functions
		function openModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};

		function openQuoteModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				templateUrl: modalTemplate,
				controller: Controller,
				windowClass: 'large-Modal',
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};

		$scope.AddFiles = function (id) {
			openModal('views/modal-templates/add-file.html', 'AddFile', {
				CurrentProfile: $scope.enquiry.LeadId,
				RequestArea: "LeadProfile"
			});
		};

		$scope.openAddNoteModal = function (id) {
			openModal('views/modal-templates/add-note.html', 'AddNoteController', {
				ObjectId: id,
				ObjectName: "SaleOrder",
				Note: ""
			});
		};


		$scope.openAppointmentsLogModal = function (id) {
			openModal('views/modal-templates/appointments-log.html', 'AppointmentsLogController', id);
		};

		$scope.openFileLogModal = function (id) {
			var data = {
				id: id,
				CurrentProfile: $scope.enquiry.LeadId,
				RequestArea: "LeadProfile"
			};
			openModal('views/modal-templates/file-log.html', 'FileLogController', data);
		};

		$scope.openMemoHistoryModal = function (id) {
			openModal('views/modal-templates/memo-history.html', "MemoHistoryController", id);
		};

		$scope.openNoteHistoryModal = function (id) {
			openModal('views/modal-templates/note-history.html', 'NoteHistoryController', id);
		};

		$scope.openQuoteOptionsModal = function (id, type) {
			var data = { id, type }
			openModal('views/modal-templates/quote-options.html', 'QuoteOptionsController', data);
		};

		let pricingAppModalInstance;

		$scope.redirectToPricingApp = function (id, type) {
			PricingAppService.getConfigValue('PortalUrl').then(function (response) {
				PricingAppService.getConfigValue('PortalCredentials').then(function (response2) {
					let portalUrl = response.data;
					let portalCredentials = response2.data;
					var data = { id, type, portalUrl, portalCredentials }
					pricingAppModalInstance = openPricingAppModal('views/modal-templates/iframe.html', 'IFrameController', data);
					pricingAppModalInstance.result.finally(function () {
						$scope.viewEnquiry(); // Refresh the Enquiry after the modal closes
					});
				});
			});
		};

		$scope.closePricingAppModal = function () {
			if (pricingAppModalInstance) {
				pricingAppModalInstance.close();
			}
		};

		function openPricingAppModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				templateUrl: modalTemplate,
				controller: Controller,
				windowClass: 'xlarge-Modal',
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};

		$scope.openSendLetterModal = function (id) {
			var Data = {
				ObjectId: id,
				ObjectType: 'enquiry',
				LetterType: 'letter',
				LetterLocation: 'Enquiries',
				ViewLocation: 'Letter'
			};
			var config = {
				LetterType: 'Standard'
			}
			openModal('views/modal-templates/send-letter.html', 'SendLetterController', Data, config);
		};
		$scope.openBrochureLetterModal = function (id) {
			var Data = {
				ObjectId: id,
				ObjectType: 'enquiry',
				LetterType: 'brochure',
				LetterLocation: 'Enquiries',
				ViewLocation: 'Brochure'
			};
			var config = {
				LetterType: 'Brochure'
			}

			var modalInstance = openModal('views/modal-templates/send-letter.html', 'SendLetterController', Data, config);
			modalInstance.result.then(function (output) {
				$scope.enquiry.BrochureSent = new Date();
				$scope.save();
			})
		};

		$scope.openSendQuoteModal = function (id) {
			var Data = {
				ObjectId: id,
				ObjectType: 'enquiry',
				LetterType: 'quote',
				LetterLocation: 'Enquiries',
				ViewLocation: 'Quote'
			};
			var config = {
				LetterType: 'Quote'
			}
			openModal('views/modal-templates/send-letter.html', 'SendLetterController', Data, config);
		};

		$scope.createAppointment = function (enquiry) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'views/modal-templates/book-appointment.html',
				controller: 'CreateAppointmentModalCtrl',
				resolve: {
					Data: function () {
						return {
							Type: 'Enquiry',
							ObjectID: enquiry.SystemId,
							Ref: enquiry.LeadId,
							sender: $rootScope.User.FullName(),
							Customer: enquiry.Forename + ' ' + enquiry.Surname,
							SelectedRecipient: enquiry.SalesPerson
						}
					}
				}
			});

			modalInstance.result.then(function (output) {
				$scope.enquiry.ChangeStatus = $scope.allStatusList.find(function (item) { return item.Name == 'Appointed' }).Id;
				$scope.save();
			});
		};

		$scope.openActivityLogModal = function (id) {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: "views/modal-templates/activity-log.html",
				controller: "ActivityLogController",
				resolve: {
					Id: function () {
						return id;
					}
				}
			});
		};

		$scope.AddNote = function () {
			var data = {
				Note: $scope.NewNote,
				ObjectId: $scope.enquiry.SystemId,
				ObjectName: "Lead"
			};
			$scope.AddingNotePromise = ($rootScope.onLineFlag) ? EnquiryModalService.addNote(data) : IpadService.addNote(data, $scope.enquiry.SystemId);
			$scope.AddingNotePromise.then(function (output) {
				NotificationService.alert('Note Added', 'Added note successfully.', 'success', {});
				$scope.NewNote = '';
			}, function (error) {
				NotificationService.alert('Note Failed', 'Failed to add the note.', 'error', {});
			});
		};

		// will need a Offline Method
		$scope.removeAlternativeContactitem = function (item) {
			$scope.removingAlternativeContactPromise = LeadProfileService.removeAlternativeContactItem({ ContactId: item.Id });
			$scope.removingAlternativeContactPromise.then(function (success) {
				NotificationService.alert('Contact Removed', 'This alternative contact item has been removed.', 'success', {});
				$scope.viewEnquiry();
			}, function (err) {
				NotificationService.alert('Contact Removal Error', 'There was an error when attemtping to remove this contact.', 'error', {});
			});
		};

		$scope.updateAlternativeContactitem = function (item) {
			var contact = Object.assign({}, item);
			var modalInstance = openModal('views/modal-templates/update-contact.html', 'UpdateAlternativeContactItemController', {
				Contact: contact
			});

			modalInstance.result.finally(function () {
				$scope.viewEnquiry();
			});
		};

		$scope.toggltNote = function () {
			$scope.lNoteToggle = !$scope.lNoteToggle;
			if ($scope.lNoteToggle)
				$scope.NoteClass = 'glyphicon glyphicon-chevron-down'
			else
				$scope.NoteClass = 'glyphicon glyphicon-chevron-up'
		};

		$scope.changeSalesPerson = function () {
			if ($scope.originalEnquiry.SalesPerson != null || $scope.originalEnquiry.SalesPerson == undefined)
				NotificationService.confirm("SalesPerson Override", "Are you sure you want to override the current Salesperson?", {}).get().on('pnotify.cancel', function () {
					$scope.enquiry.SalesPerson = $scope.originalEnquiry.SalesPerson;
				});
		};

		function fetchCloudFileNumber() {
			var data = {
				SynoSid: $rootScope.SynoSid,
				LeadId: $scope.enquiry.LeadId
			}

			if ($scope.enquiry.LeadId == undefined)
				return;

			$scope.FetchCloudFileNumberPromise = EnquiryModalService.fetchCloudFileNumber(data);
			$scope.FetchCloudFileNumberPromise.then(function (output) {
				$rootScope.NumberOfFiles = output.data;
			});
		}

		$scope.ViewLeadProvider = function () {
			$location.path('/lead-provider-details/' + $scope.enquiry.LeadProvider.Id);
		}

		$scope.QuotedPriceUpdated = function () {
			$scope.quotePriceChanged = parseInt($scope.enquiry.QuotedPrice) > 0 ? true : false;
		}

		$scope.clearRecOptions = function (option) {
			if (option == 1) {
				$scope.enquiry.QuoteRecOption2 = false;
				$scope.enquiry.QuoteRecOption3 = false;
			}
			else if (option == 2) {
				$scope.enquiry.QuoteRecOption1 = false;
				$scope.enquiry.QuoteRecOption3 = false;
			}
			else if (option == 3) {
				$scope.enquiry.QuoteRecOption1 = false;
				$scope.enquiry.QuoteRecOption2 = false;
			}
			//EnableDisableQuoteButtons();
			$scope.EnableDisabledQuoteBtn();
			$scope.save(true);
		}
		/*
				$scope.EnableDisableQuoteButtons = function () {
					if ($scope.enquiry.QuoteRecOption1 || $scope.enquiry.QuoteRecOption2 || $scope.enquiry.QuoteRecOption3) {
						// Enable Quote Buttons
						$scope.enquiry.PreviewQuote
					}
					else {
						// Disable Quote Buttons
					}
				}
		*/
		$scope.EnableDisabledQuoteBtn = function () {
			if ($scope.enquiry.QuoteRecOption1 || $scope.enquiry.QuoteRecOption2 || $scope.enquiry.QuoteRecOption3) {
				// Enable Quote Buttons
				$scope.QuoteIsDisabled = false;
			}
			else {
				// Disable Quote Buttons
				$scope.QuoteIsDisabled = true;
			}
		}

		$scope.ReturnToHO = function (Id) {
			var Data = {
				Ref: Id,
			}
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'views/modal-templates/return-to-ho.html',
				controller: 'ReturnToHOController',
				resolve: {
					Data: function () {
						return Data;
					}
				}
			});
			modalInstance.result.then(function (output) {
				$scope.loadEnquiry();
			});
		}

		$scope.GetLeadSummary = function () {
			//alert('V2 tempting to download list of files');
			if ($rootScope.SynoSid != '') {
				$scope.template = {};
				//get file from cloud
				var data = {
					Sid: $rootScope.SynoSid,
					CurrentProfile: "",
					SelectedFileName: "LeadSummary",
					SelectedFileType: ".docx",
					LetterLocation: "Static",
					ViewLocation: "Static Files",
				}
				var fileGen = {
					ObjectId: $scope.enquiry.SystemId,
					ObjectType: 'LeadSummary',
					LetterType: 'LeadSummary',
					LetterLocation: "Static",
					ViewLocation: "Static Files",
				}

				$scope.FileDownloadPromice = EnquiryModalService.downloadTemplate(data);
				$scope.FileDownloadPromice.then(function (output) {
					var string = 'data:' + output.data.FileType + ';base64,' + output.data.Base64;
					var byteString = window.atob(string.split(',')[1]);
					var ab = new ArrayBuffer(byteString.length);
					var ia = new Uint8Array(ab);
					for (var i = 0; i < byteString.length; i++) {
						ia[i] = byteString.charCodeAt(i);
					};

					var type = $rootScope.MimeArray.find(arr => arr.ext === output.data.FileType);

					var blob = new Blob([ab], {
						type: type.mime
					});

					// move file to $scope.template
					$scope.template.file = new File([blob], output.data.FileName + output.data.FileType);

					//generate letter
					$scope.LetterPromise = LetterGenerationService.MakeLetter(fileGen, $scope.template.file);
					$scope.LetterPromise.then((output) => {
						$scope.downloadCompleted = true;
						var file = new Blob([output.data])
						saveAs(file, $scope.template.file.name);
					}, (error) => {
						NotificationService.alert('Lead Summary failed', 'There was a error fetching the lead summary', 'error', {})
					})
				})
			}
			else {
				NotificationService.alert('Not logged into cloud', 'You are currently not logged into the cloud storage, lead summary failed.', 'error', {});
			}
		}

		$scope.RemoveAppointment = function () {
			if (confirm('Are you sure you want to delete this appointment?')) {
				var id = $scope.enquiry.AppointmentId;
				$scope.updatingAppointmentPromise = AppointmentService.UpdateAppointmentBooking({ Id: id, isDeleted: true });
				$scope.updatingAppointmentPromise.then(success => {
					NotificationService.alert('Appointment Removed', 'This appointment has been successfully removed.', 'success', {});
					$scope.enquiry.Appointment = null;
					$scope.enquiry.Appointment2 = null;
				}, err => {
					NotificationService.alert('Appointment Error', 'There was an error updating the associated appointment with this maintenance booking.', 'error', {});
				});
			}
		}

		$scope.LoadLPInfoForEnquiry = function (LP) {
			if ($scope.enquiry.LPPaymentInfoDisplay == true) {
				$scope.enquiry.LPAgreementDate = LP.AgreementDate;
				FetchLPCommission(LP.Id);
			}
		}

		function FetchLPCommission(LPId) {
			if ($scope.enquiry.LPPaymentInfoDisplay == true) {
				$scope.FetchLPCommissionPromise = LeadProfileService.FetchLPCommissionDecimal(LPId);
				$scope.FetchLPCommissionPromise.then(function (output) {
					$scope.enquiry.LPCommissionPercentage = output.data;
				});
			}
		}

		$scope.CalculateLPPayment = function (CommissionPercentage) {
			//need to contact backend to find out if we can calculate a price at this point along with accuracy of result.
			if ($scope.enquiry.LPPaymentInfoDisplay == true) {
				if (CommissionPercentage != null && CommissionPercentage > 0) {
					var data = {
						CP: CommissionPercentage,
						LeadId: $scope.enquiry.LeadId
					}
					$scope.CalculateLeadProviderPaymentPromise = LeadProfileService.CalculateLPPayment(data);
					$scope.CalculateLeadProviderPaymentPromise.then(function (output) {
						if (output.data.Price != null && output.data.Price > 0) {
							$scope.enquiry.LPPaymentValue = output.data.Price;
						}
						else if (output.data.SaleExists == "false") {
							NotificationService.alert("Calculate Commission Error!", "No Sale Order found for this Enquiry", error, {});
						}
						else if (output.data.NoOrderValue == "false") {
							NotificationService.alert("Calculate Commission Error!", "No Order value found for this Enquiry's Sale Order", error, {});
						}
						else {
							NotificationService.alert("Calculate Commission Error!", "An error has occured while trying to calculate the Lead Providers Commission Payment", error, {});
						}
					});
				}
				else {
					NotificationService.alert("Calculate Commission Error!", "Cannot calculate commission value if Commission is empty or set to 0", error, {});
				}
			}
		}

		$scope.openCreatePaymentModal = function (OrderId, Type) {
			var data = {
				OrderId: OrderId,
				PaymentId: 0,
				type: Type,
				Payment: null,
				PickList: $scope.picklist
			};
			var modalInstance = openModal('views/modal-templates/payment.html', 'CreatePaymentController', data);
			modalInstance.result.then(function () {
				$location.path('/lead-details/' + $scope.enquiry.LeadId);
			});
		}

		// Add event listener for messages from PricingApp to close commission modal
		window.addEventListener('message', function (event) {
			if (event.data === 'closePricingAppModal') {
				$scope.closePricingAppModal();
			}
		}, false);

	}])
	.controller('SearchDuplicatesCtrl', ["$scope", "$modal", "items", "$modalInstance", "WebEnquiryService", "$location", "NotificationService", function ($scope, $modal, items, $modalInstance, WebEnquiryService, $location, NotificationService) {
		$scope.searchForDuplicates = function () {
			$scope.OutOfArea = false;
			$scope.Special = false;
			$scope.GetDuplicatesPromise = WebEnquiryService.searchForDuplicate(items);
			$scope.GetDuplicatesPromise.then(function (output) {
				$scope.duplicates = output.data.Duplicates;
				$scope.OutOfArea = output.data.OutOfArea;
				$scope.SearchPostcode = output.data.SearchPostcode;
				$scope.Special = output.data.Special;
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Web Enquiry Error', 'There was an error retriving duplicate enquiries.', 'error', {});
			});
		};

		$scope.viewDuplicate = function (col) {
			//Close modal
			//Redirect to appropriate page
			if (col.Type == "Prospect")
				window.open('/#/prospect-details/' + col.Ref);
			else if (col.Type == "Order")
				window.open('/#/sales-order/' + col.Ref);
			else
				window.open('/#/enquiry-details/' + col.Ref);
			$modalInstance.close();
		};

		$scope.close = function () {
			$modalInstance.close();
		};
	}])
	.controller('WebEnquiryController', ["$scope", "$location", "$modal", "WebEnquiryService", "$routeParams", "NotificationService", "ApiService", function ($scope, $location, $modal, WebEnquiryService, $routeParams, NotificationService, ApiService) {
		//Retrieve record
		$scope.getEnquiry = function () {
			$scope.GetWebEnquiryPromise = WebEnquiryService.getWebEnquiryById($routeParams.id);
			$scope.GetWebEnquiryPromise.then(function (output) {
				$scope.webEnquiry = output.data;
			}, function (error) {
				$scope.fetchedDataError = true;
				NotificationService.alert('Web Enquiry Error', 'There was an error retriving the web enquiry.', 'error', {});
			});
		};

		$scope.searchForDuplicates = function () {
			ApiService.promiseOpenModal('views/modal-templates/web-enquiry-duplicates.html', 'SearchDuplicatesCtrl', $routeParams.id);
		};

		$scope.removeRecord = function () {
			if (confirm('Are you sure you want to remove this record?')) {
				$scope.RemoveWebEnquiryPromise = WebEnquiryService.removeRecord($routeParams.id);
				$scope.RemoveWebEnquiryPromise.then(function (output) {
					$location.path('/presales');
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Web Enquiry Error', 'There was an error removing the web enquiry.', 'error', {});
				});
			}
		};

		$scope.saveAsLead = function () {
			if (confirm('Are you sure you want to save this record as a lead?')) {
				$scope.SavingWebEnquiryPromise = WebEnquiryService.saveAsLead($routeParams.id);
				$scope.SavingWebEnquiryPromise.then(function (output) {
					$location.path('/enquiry-details/' + output.data.LeadId);
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Web Enquiry Error', 'There was an error saving the web enquiry as a lead.', 'error', {});
				});
			}
		};

		$scope.updateWebEnquiry = function () {
			var data = {
				EnquiryId: $scope.webEnquiry.EnquiryId,
				Postcode: $scope.webEnquiry.Postcode,
				Phone: $scope.webEnquiry.Phone,
				Email: $scope.webEnquiry.Email
			}
			$scope.updateWebEnquiryPromise = WebEnquiryService.updateWebEnquiry(data);
			$scope.updateWebEnquiryPromise.then(function (output) {
				if (output.data == 'true')
					NotificationService.alert('Web Enquiry Updated', 'The web enquiry has been successfully updated.', 'success', {});
				else
					NotificationService.alert('Web Enquiry Error', 'The web enquiry was unable to update.', 'error', {});
			});
		}

	}])
	.controller('SalesOrderController', ["$rootScope", "$scope", "$location", "$modal", "$routeParams", "$filter", "NotificationService", "PickListService", "SaleOrderService", "LeadProfileService", "SearchService", "StaffService", "AppointmentService", "ParamService", "EnquiryModalService", "IpadService", "MaintenanceService", "LetterGenerationService", "PricingAppService", function ($rootScope, $scope, $location, $modal, $routeParams, $filter, NotificationService, PickListService, SaleOrderService, LeadProfileService, SearchService, StaffService, AppointmentService, ParamService, EnquiryModalService, IpadService, MaintenanceService, LetterGenerationService, PricingAppService) {

			$scope.init = function () {

				// Blank - false do not show buttons.
				// Has SID - true show buttons.
				$scope.fileButtonsEnabled = $rootScope.SynoSid != '' && $rootScope.SynoSid != 'NoLogin';
				$scope.fileSpinnerShow = $rootScope.SynoSid != 'NoLogin' && !$scope.fileButtonsEnabled;

				// Ticket 22842790 : Rights to alter guarantee.
				$scope.isUserAdminG = $rootScope.User.Roles.filter(r => r.Name == 'System Administrator').length > 0;
				$scope.isOperationsManagerG = $rootScope.User.Roles.filter(r => r.Name == 'Operations Manager').length > 0;

				$scope.guaranteeRights = $scope.isUserAdminG || $scope.isOperationsManagerG;
				$scope.noGuaranteeRights = !$scope.guaranteeRights;

				$scope.GuaranteeStillActive = true;
				$scope.customer = {};
				$scope.Search = {};
				$scope.orderDetails = {};
				$scope.originalOrderDetails = {};
				$scope.enquiry = {};
				$scope.OrderDetailLine = [];
				$scope.lNoteToggle = false;
				$scope.NoteClass = 'glyphicon glyphicon-chevron-up';
				$scope.Appointments = {};
				$scope.openPopups = {};
				$scope.disabled = false;
				$scope.UnhideCustomRails = false;
				$scope.allStatusList = [];
				$rootScope.NumberOfFiles = 0;

				$scope.filteredPaymentTypes = [];

				$scope.AlternativeContactItem = {
					ContactName: null,
					TypeOfContactata: null,
					ContactData: null
				};

				$scope.searchExpanded = false;

				//Get All Status list
				$scope.GetAllStatusPromise = ($rootScope.onLineFlag) ? MaintenanceService.getAllStatuses() : IpadService.getStatusLists();
				$scope.GetAllStatusPromise.then(function (output) {
					$scope.allStatusList = ($rootScope.onLineFlag) ? output.data.StatusList : output;
				}, function (error) {
					NotificationService.alert('Status Error', 'There was an error gathering all the data, please try again.', 'error', {});
				});

				getPicklists();

				getPaymentParams();
				// Ticket 22842790 : Guarantee is 1 or 5 years!
				$scope.GuaranteeOptions = [{
					Title: "1 Year",
					Value: 1
				},
				{
					Title: "5 Years",
					Value: 5
				}];
				getStaff();
				$scope.total;

				$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Fitter' || r.Name == 'Sales Person' || r.Name == 'Sales Person/Fitter').length > 0 ? false : true;
				$scope.isUserFitter = $rootScope.User.Roles.filter(r => r.Name == 'Fitter' || r.Name == 'Sales Person/Fitter').length > 0 ? true : false;
				$scope.isOnlyFitterUser = $rootScope.User.Roles.filter(r => r.Name == 'Fitter').length > 0 ? true : false;
				$scope.isOnMobile = window.mobileAndTabletcheck();
				$scope.isUserSalesPerson = $rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0 ? true : false;
				$scope.IsUserContractOrManager = $rootScope.User.Roles.filter(r => r.Name == 'Contracts' || r.Name == 'Operations Manager' || r.Name == 'Sales Manager' || r.Name == 'Field Manager' || r.Name == 'Area Manager').length > 0 ? true : false;
				$scope.isUserAdmin = $rootScope.User.Roles.filter(r => r.Name == 'System Administrator').length > 0;
				$scope.isUserAccounts = $rootScope.User.Roles.filter(r => r.Name == 'Accounts').length > 0;
				$scope.isUserContracts = $rootScope.User.Roles.filter(r => r.Name == 'Contracts').length > 0;
				$scope.isUserOperationsManager = $rootScope.User.Roles.filter(r => r.Name == 'Operations Manager').length > 0;
				$scope.canUserRemoveAppointments = $rootScope.User.Roles.filter(r => r.Name == 'Contracts' || r.Name == 'Operations Manager' || r.Name == 'Sales Manager' || r.Name == 'Field Manager' || r.Name == 'System Administrator').length > 0 ? true : false;
				$scope.isUserPricingApp = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Pricing').length > 0) ? true : false;

				$scope.Hidden = {
					SecondCustomer: false,
					InstallAddress: false,
					ContactAddress: false,
					ContactInformation: false,
					AlternativeContact: false,
					LeadSource: false,
					SourceDetails: false,
					SiteDetails: false,
					AccessDetails: false,
					ActionInfo: false,
					ContactEvents: false,
					OtherCompanyQuotes: false
				};
			};

			// Synology logged in, show buttons.
			$scope.$on("SIDSet", function (evt, data) {
				$scope.fileSpinnerShow = false;
				if (data != undefined) {
					fetchCloudFileNumber();
					$scope.fileButtonsEnabled = true;
				}
			});

			$scope.initHrefCheck = function () {
				if (window.location.href.indexOf('deposit') > 0)
					$location.path('/sales-order/' + $scope.orderDetails.LeadId);
				else
					$scope.init();
			}


			$scope.toggleHidden = function (key) {
				$scope.Hidden[key] = !$scope.Hidden[key];
			}

			$scope.$on('$locationChangeStart', function (event) {
				if (angular.equals($scope.orderDetails, $scope.originalOrderDetails)) {
				}
				else {
					var answer = confirm("Unsaved data will be lost, are you sure you want to leave this page?")
					if (!answer) {
						event.preventDefault();
					}
				}
			});

			window.onbeforeunload = function (event) {
				if (angular.equals($scope.orderDetails, $scope.originalOrderDetails)) { }
				else {
					var message = 'Unsaved data will be lost.Are you sure you want to close the page ?';
					if (typeof event == 'undefined') {
						event = window.event;
					}
					if (event) {
						event.returnValue = message;
					}
					return message;
				}
			}

			$scope.goToEnquiry = function () {
				$location.path('/enquiry-details/' + $scope.orderDetails.LeadId);
			};

			$scope.newLinkedEnquiry = function () {
				if (confirm("Are you sure you want to create a new linked enquiry?")) {
					$location.path('/enquiry-details/' + $scope.orderDetails.LeadId + '/new');
				}

			}

			$scope.openQuoteOptionsModal = function (id, type) {
				var data = { id, type }
				openModal('views/modal-templates/quote-options.html', 'QuoteOptionsController', data);
			};

			let pricingAppModalInstance;

			$scope.redirectToPricingApp = function (id, type) {
				PricingAppService.getConfigValue('PortalUrl').then(function (response) {
					PricingAppService.getConfigValue('PortalCredentials').then(function (response2) {
						let recOptions = {
							QuoteRecOption1: $scope.enquiry.QuoteRecOption1,
							QuoteRecOption2: $scope.enquiry.QuoteRecOption2,
							QuoteRecOption3: $scope.enquiry.QuoteRecOption3
						};
						let option = PricingAppService.getQuoteRecOption(recOptions);
						let portalUrl = response.data;
						let portalCredentials = response2.data;
						var data = { id, type, portalUrl, option, portalCredentials }
						pricingAppModalInstance = openPricingAppModal('views/modal-templates/iframe.html', 'IFrameController', data);
						pricingAppModalInstance.result.finally(function () {
							$scope.viewEnquiry(); // Refresh the Enquiry after the modal closes
						});
					});
				});
			};

			$scope.closePricingAppModal = function () {
				if (pricingAppModalInstance) {
					pricingAppModalInstance.close();
				}
			};

			function openPricingAppModal(modalTemplate, Controller, modalData, config) {
				return $modal.open({
					animation: true,
					templateUrl: modalTemplate,
					controller: Controller,
					windowClass: 'xlarge-Modal',
					resolve: {
						data: function () {
							return modalData;
						},
						Id: function () {
							return modalData;
						},
						config: function () {
							return config
						}
					}
				});
			};

			$scope.InGuaranteeValid = function () {
				if ($scope.orderDetails.GuaranteeIssued == null || $scope.orderDetails.GuaranteeIssued == '0001-01-01T00:00:00') {
					$scope.GuaranteeStillActive = true; // cant have a guarantee expire if there isnt a guarantee given out!
					$scope.displayGuaranteeDate = "No Date Issued.";
				}
				else {
					var now = moment();
					var GuaranteeValid = moment($scope.orderDetails.GuaranteeIssued);
					var guaranteeYear = GuaranteeValid.get('year') + $scope.orderDetails.Guarantee;
					GuaranteeValid.set('year', guaranteeYear);
					$scope.GuaranteeStillActive = GuaranteeValid.isAfter(now);
					$scope.displayGuaranteeDate = GuaranteeValid.format('DD/MM/YYYY');
				}
			}

			function hasBalanceChanged() {
				var today = moment();
				today.add(7, 'Days');
				$scope.BalanceChange = moment($scope.orderDetails.BalanceChange) >= today;
			}

			function getPaymentParams() {
				if ($rootScope.onLineFlag) {
					$scope.getParamByEntityAndFieldPromise = ($rootScope.onLineFlag) ? ParamService.getParamByEntityAndField("SaleOrder", "PaymentType") :
						IpadService.getParams();
					$scope.getParamByEntityAndFieldPromise.then(function (output) {
						$scope.PaymentParams = ($rootScope.onLineFlag) ? output.data.Params : output;
					}, function (error) {
						NotificationService.alert('Params\' Not Retrieved', 'The requested parameter were not received.', 'error', {});
					});
				}
			};
			$scope.toggltNote = function () {
				$scope.lNoteToggle = !$scope.lNoteToggle;
				if ($scope.lNoteToggle)
					$scope.NoteClass = 'glyphicon glyphicon-chevron-down'
				else
					$scope.NoteClass = 'glyphicon glyphicon-chevron-up'
			}

			function getPicklists() {
				//Get All picklist
				$scope.PicklistPromise = ($rootScope.onLineFlag) ? PickListService.getPickListByPickListName(['Title', 'Contact', 'AppointmentTime', "ddmBraidColour",
					"ddmFabric",
					"ddmHandleColour",
					"ddmEndCapColour",
					"ddmLayout",
					"ddmParking",
					"ddmPosition",
					"ddmProduct",
					"ddmProductType",
					"ddmRailColour",
					"ddmRemoteColour",
					"ddmShape",
					"ddmSupplier",
					"ddmWallType",
					"ddmOperation",
					"ddmContactType",
					"ddmWireColour",
					'ddmQualityControl',
					'BlindType',
					'BlindUse',
					'ContactType',
					'ddmContractTerm',
					'altContactType',
					'ddmRidgeHeight',
					'ddmSiteAccess',
					'ddmAccessEquipment',
					'ContractType',
					'InitialContactMethod',
					'System',
					'ddmDamage',
					'ddmPaymentTypes',
					'ddmInitialContactMethod',
					'ddmContractType',
					'ddmHardwareType',
					'ddmMicro-CableColour',
				]) : IpadService.getPicklist();
				$scope.PicklistPromise.then(function (output) {
					$scope.PickList = ($rootScope.onLineFlag) ? output.data.PickListEntries : output;
					$scope.getOrderData($routeParams.id);
					$scope.viewEnquiry();
					$scope.GetAppointments($routeParams.id);
					$scope.CustomPickListId = 0;
					var CustomPickList = $scope.PickList.find(picklist => picklist.PickListEntry == 'Custom' && picklist.PickListName == 'ddmRailColour');
					if (CustomPickList)
						$scope.CustomPickListId = CustomPickList.Id;
				}, function (error) {
					NotificationService.alert('Pick List Error', 'There was an error gathering all the pick list data, please try again.', 'error', {});
				});
			};

			$scope.revealCustomRails = function (rails) {
				if (rails == $scope.CustomPickListId)
					$scope.UnhideCustomRails = true;
			};

			function getStaff() {
				$scope.GetAllStaffPromise = ($rootScope.onLineFlag) ? StaffService.getAllStaff() : IpadService.getAllStaff();
				$scope.GetAllStaffPromise.then(function (output) {
					$scope.allStaffList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
				}, function (error) {
					NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
				});
			};

			$scope.createAppointment = function (order) {
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: 'views/modal-templates/book-appointment.html',
					controller: 'CreateAppointmentModalCtrl',
					resolve: {
						Data: function () {
							return {
								Type: 'Pre-Survey',
								ObjectID: order.SystemId,
								Ref: order.LeadId,
								sender: $rootScope.User.FullName(),
								Customer: order.Forename + ' ' + order.Surname,
								SelectedRecipient: order.Fitter != null ? order.Fitter.Id : null,
							}
						}
					}
				});

				modalInstance.result.then(function () {
					$scope.initHrefCheck();
				});
			};

			$scope.GetAppointments = function (id) {
				if (id != "new" && $rootScope.onLineFlag) {
					$scope.GetAppointmentsPromise = AppointmentService.GetSalesOrderAppointments(id);
					$scope.GetAppointmentsPromise.then(function (output) {
						$scope.Appointments = {
							Survey: output.data.Survey,
							Installation: output.data.Installation,
						}
					}, function (err) {
						NotificationService.alert('Appointments Error', 'There was an error gathering all the data, please try again.', 'error', {});

					})
				}
			};

			$scope.UpdateAppointments = function (id, appointments) {
				if ($rootScope.onLineFlag) {
					var data = {
						OrderId: id,
						Survey: appointments.Survey,
						Installation: appointments.Installation
					}
					$scope.UpdateAppointmentsPromise = AppointmentService.UpdateSalesOrderAppointments(data);
					$scope.UpdateAppointmentsPromise.then(function () {
						$scope.GetAppointments($routeParams.id);
					}, function (err) {
						NotificationService.alert('Appointments Error', 'There was an error Saveing all the data, please try again.', 'error', {});
					})
				}
			};

			function getOrderLine(created, sortGuarantee) {
				$scope.getOrderLinePromise = ($rootScope.onLineFlag) ? SaleOrderService.getOrderLines($scope.orderDetails.OrderId) :
					IpadService.getOrderlines($scope.orderDetails.Id);
				$scope.getOrderLinePromise.then(function (output) {
					$scope.OrderDetailLine = ($rootScope.onLineFlag) ? output.data.Orderlines : output;
					$scope.OrderDetailLine.map((m) => {
						m.Product = $scope.PickList.find((x) => {
							return (x.Id == m.Product)
						});
						return m;
					});
					$scope.total = $scope.GetTotal($scope.OrderDetailLine);
					if ($scope.OrderDetailLine.length > 0) {
						for (var i = 0; i < $scope.OrderDetailLine.length; i++) {
							if ($scope.OrderDetailLine[i].Rails == $scope.CustomPickListId)
								$scope.UnhideCustomRails = true;
						}
					}
					if (sortGuarantee)
						saveGuaranteeLevelForOrder($scope.orderDetails.OrderId);
				}, function (error) {
					NotificationService.alert('Order Line Error', 'There was an error getting details of the sale order.', 'error', {});
				});
			};

			$scope.GetTotal = function (Lines) {
				var total = 0;
				angular.forEach(Lines, function (value, key) {
					total += (value.cost * value.qty);
				});
				return total;
			}
			$scope.newLine;
			$scope.openCalloutI = function (Item) {
				$scope.openProductDetailModal({
					item: Item,
					list: $scope.OrderDetailLine,
					PickList: $scope.PickList,
				})
			};
			$scope.saveOrderLine = function (newline) {
				newline.SalesOrder = $scope.orderDetails.OrderId;
				$scope.creatingNewOrderLinePromise = ($rootScope.onLineFlag) ?
					SaleOrderService.newOrderLine(newline) :
					IpadService.addOrderline({ orderId: newline.SalesOrder, orderline: newline });
				$scope.creatingNewOrderLinePromise.then(function () {
					$scope.newLine = {
						Product: '',
						ProductType: '',
						Rails: '',
						Fabric: '',
						Operation: '',
						Electric: '',
						SolarLining: '',
						HardwareType: '',
						RoomnName: '',
						MicroCableColour: '',
						BraidType: '',
						Rails: '',
						HandleColour: '',
					};
					getOrderLine(true, true);
				});

			};
			$scope.SaveUpdatedOrderLine = function (OrderLine) {
				delete OrderLine.$$hashKey;
				if (OrderLine.Product instanceof Object) { OrderLine.Product = OrderLine.Product.Id; }
				$scope.updatingOrderLinePromise = ($rootScope.onLineFlag) ? SaleOrderService.updateOrderLine(OrderLine) : IpadService.updateOrderline(OrderLine);
				$scope.updatingOrderLinePromise.then(function () {
					$scope.newLine = {
						Product: '',
						ProductType: '',
						Rails: '',
						Fabric: '',
						Operation: '',
						Electric: '',
						SolarLining: '',
						RemoteColour: ''
					};
					getOrderLine(true, true);
				});

			};

			$scope.deleteOrderLine = function (id) {
				if (confirm('Are you sure you want to delete this order line? This action cannot be undone!')) {
					$scope.deletingOrderLinePromise = ($rootScope.onLineFlag) ? SaleOrderService.deleteOrderLine({
						OrderLineId: id
					}) :
						IpadService.deleteOrderline(id);
					$scope.deletingOrderLinePromise.then(success => {
						NotificationService.alert('Line Removed', 'The order line has been successfully removed.', 'success', {});
						getOrderLine(false, true);
					}, err => {
						NotificationService.alert('Line Error', 'There was an error removing this order line, please try again.', 'error', {});
					});
				};
			};



			$scope.getOrderData = function (id) {
				if (id != "new") {
					$scope.gettingOrderDataPromise =
						($rootScope.onLineFlag) ? SearchService.viewOrder(id) : IpadService.getSalesOrderByLeadId(id);
					$scope.gettingOrderDataPromise.then(function (output) {
						$scope.orderDetails = ($rootScope.onLineFlag) ? output.data.OrderDetail : output;

						//add SalesPerson who have left to the enquiry ddm
						if ($scope.orderDetails.SalesPerson && $scope.allStaffList.filter(e => e.Id === $scope.orderDetails.SalesPerson.Id).length == 0) {
							$scope.allStaffList.push({ Id: $scope.orderDetails.SalesPerson.Id, Email: $scope.orderDetails.SalesPerson.EmailAddress, FirstName: '[LEFT] ' + $scope.orderDetails.SalesPerson.FirstName, LastName: $scope.orderDetails.SalesPerson.LastName, Roles: $scope.orderDetails.SalesPerson.Roles });
						}

						//add Fitter who have left to the enquiry ddm
						if ($scope.orderDetails.Fitter && $scope.allStaffList.filter(e => e.Id === $scope.orderDetails.Fitter.Id).length == 0) {
							$scope.allStaffList.push({ Id: $scope.orderDetails.Fitter.Id, Email: $scope.orderDetails.Fitter.EmailAddress, FirstName: '[LEFT] ' + $scope.orderDetails.Fitter.FirstName, LastName: $scope.orderDetails.Fitter.LastName, Roles: $scope.orderDetails.Fitter.Roles });
						}
						if ($rootScope.SynoSid != '') {
							fetchCloudFileNumber();
						}
						$scope.InGuaranteeValid();
						$scope.TotalBlinds = $scope.orderDetails.RoofBlinds + $scope.orderDetails.SideBlinds + $scope.orderDetails.GableBlinds + $scope.orderDetails.HouseBlinds + $scope.orderDetails.LanternBlinds;
						if ($scope.orderDetails.OrderValue != null)
							$scope.orderDetails.OrderValue = currency($scope.orderDetails.OrderValue, 'Order Value');
						if ($scope.orderDetails.DepositAmount != null)
							$scope.orderDetails.DepositAmount = currency($scope.orderDetails.DepositAmount, 'Deposit Amount');
						hasBalanceChanged();
						if ($scope.orderDetails.Balance != null)
							$scope.orderDetails.Balance = currency($scope.orderDetails.Balance, 'Balance');
						var totalAmount = 0;
						if ($scope.orderDetails.Payments) {
							for (var i = 0; i < $scope.orderDetails.Payments.length; i++) {
								currency($scope.orderDetails.Payments[i], 'Amount');
								totalAmount = totalAmount + parseFloat($scope.orderDetails.Payments[i].Amount);
							}
						}
						if ($scope.orderDetails.PaymentContirm == null && totalAmount >= parseFloat($scope.orderDetails.OrderValue)) {
							$scope.orderDetails.PaymentContirm = Date.now();
						}
						else if ($scope.PaymentContirm != null && totalAmount < parseFloat($scope.orderDetails.OrderValue)) {
							$scope.orderDetails.PaymentContirm = null;
						}
						getDifficulty();
						getNotes();
						getOrderLine(false, false);
						angular.copy($scope.orderDetails, $scope.originalOrderDetails);
						if ($scope.orderDetails.ProFormaRequired && $scope.orderDetails.InstallationDueDate) {
							if (!$scope.orderDetails.ProFormaSent) {
								var now = moment(),
									installation = moment($scope.orderDetails.InstallationDueDate);
								var diff = installation.diff(now, 'days');
								if (diff > 0 && diff <= 14)
									NotificationService.alert('Pro-forma Customer', 'The pro-forma invoice for this customer has not been sent and it is within two weeks until installation.', 'warning', {});
							};
						};
						//GET LATEST NOTE HERE
						if ($rootScope.onLineFlag) {
							$scope.gettingNoteHistoryPromise = EnquiryModalService.getNoteHistory($scope.orderDetails.OrderId); // need to added a offline method for this.
							$scope.gettingNoteHistoryPromise.then(function (output) {
								if (output.data.Notes && output.data.Notes.length)
									$scope.LatestNote = output.data.Notes[0];
							}, function (error) {
								NotificationService.alert('Enquiry Error', 'There was an error issuing the enquiry.', 'error', {});
							});
						}
						//if first time opening sale order, open up deposit modal.
						if (window.location.href.indexOf('deposit') > 0 && $scope.orderDetails.Payments.length == 0) {
							$scope.openCreatePaymentModal($scope.orderDetails.OrderId, null, 'Deposit');
						}

						// Check against payment types for ones set not to show in portal
						var paymentTypes = $filter('picklistFilter')($scope.PickList, 'ddmPaymentTypes');
						$scope.filteredPaymentTypes = [];
						paymentTypes.forEach(function (arrayItem) {
							if (arrayItem.Id == $scope.orderDetails.BalanceMethod && arrayItem.ShowInPortal != true) {
								$scope.filteredPaymentTypes.push(arrayItem);
							}
							if (arrayItem.ShowInPortal == true) {
								$scope.filteredPaymentTypes.push(arrayItem);
							}
						});

					}, function (error) {
						NotificationService.alert('Order Error', 'There was an error getting details of the sale order.', 'error', {});
					});
				}
			};

			$scope.viewEnquiry = function (appointed) {
				$scope.GetEnquiryPromise = ($rootScope.onLineFlag) ? LeadProfileService.getEnquiryById($routeParams.id) : IpadService.getLeadProfile($routeParams.id);
				$scope.GetEnquiryPromise.then(function (output) {
					$scope.enquiry = ($rootScope.onLineFlag) ? output.data.LeadDetail : output;

					//if ($scope.enquiry.SampleOrders) $scope.enquiry.SampleOrders.forEach((order) => {
					//	order.ProductString = $scope.picklist.find(pl => pl.Id == order.Product).PickListEntry;
					//	order.FabricString = $scope.picklist.find(pl => pl.Id == order.Fabric).PickListEntry;
					//	order.RailTypeString = $scope.picklist.find(pl => pl.Id == order.RailType).PickListEntry;
					//	order.RailColourString = $scope.picklist.find(pl => pl.Id == order.RailColour).PickListEntry;
					//	//$scope.formatSampleDate(order, true);
					//});
					//getDifficulty();

					if (window.location.href.indexOf('new') > 0) {
						$scope.save();
					}
					//GET LATEST NOTE HERE

					$scope.gettingNoteHistoryPromise = EnquiryModalService.getNoteHistory($scope.enquiry.SystemId);
					$scope.gettingNoteHistoryPromise.then(function (output) {
						if (output.data.Notes && output.data.Notes.length)
							$scope.LatestNote = output.data.Notes[0];
					}, function (error) {
						NotificationService.alert('Enquiry Error', 'There was an error issuing the enquiry.', 'error', {});
					});

					//UPDATE STATUS
					if (appointed) {
						$scope.enquiry.ChangeStatus = 45732;
						$scope.save();
					};
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Enquiry Error', 'There was an error retriving the enquiry.', 'error', {});
				});
			};

			function getDifficulty() {
				$scope.gettingDifficultyPromise = ($rootScope.onLineFlag) ? LeadProfileService.getDifficulty($scope.orderDetails.LeadId) :
					IpadService.getLeadDifficulty($scope.orderDetails.LeadId);
				$scope.gettingDifficultyPromise.then(data => {
					$scope.difficultCustomer = ($rootScope.onLineFlag) ? data.data.Difficult : data;
					if ($scope.difficultCustomer) {
						$scope.DifficultCustomerStyle = {
							"color": "#e75a5a",
						}
					} else {
						$scope.DifficultCustomerStyle = {
							"color": "#7dcc93",
						}
					}
				}, err => {
					NotificationService.alert('Difficulty Error', 'There was an error retriving the difficulty of this customer.', 'error', {});
				});
			};

			$scope.toggleDifficult = function () {
				if ($rootScope.onLineFlag) {
					$scope.settingDifficultyPromise = LeadProfileService.setDifficulty({
						LeadId: $scope.orderDetails.LeadId
					});
					$scope.settingDifficultyPromise.then(success => {
						NotificationService.alert('Customer Type Changed', "", 'success', {});
						getDifficulty();
					}, err => {
						NotificationService.alert('Customer Type Change Error', '', 'error', {});
					});
				} else {
					IpadService.toggleLeadDifficulty($scope.orderDetails.LeadId);
				}
			};

			$scope.save = function () {
				$scope.disabled = true;
				$scope.saveEnquiry();
				angular.copy($scope.orderDetails, $scope.originalOrderDetails);
				$scope.disabled = false;
			}

			$scope.saveSaleOrder = function () {
				if (!$scope.orderDetails.ProFormaRequired && $scope.orderDetails.ProFormaSent) {
					NotificationService.alert('Update Error', 'If pro-forma invoicing is not required then remove the pro-forma sent date.', 'error', {});
					return;
				};

				var totalAmount = 0;
				for (var i = 0; i < $scope.orderDetails.Payments.length; i++) {
					currency($scope.orderDetails.Payments[i], 'Amount');
					totalAmount = totalAmount + parseFloat($scope.orderDetails.Payments[i].Amount);
				}
				if ($scope.orderDetails.PaymentContirm == null && totalAmount >= parseFloat($scope.orderDetails.OrderValue)) {
					$scope.orderDetails.PaymentContirm = Date();
				}
				else if ($scope.PaymentContirm != null && totalAmount < parseFloat($scope.orderDetails.OrderValue)) {
					$scope.orderDetails.PaymentContirm = null;
				}

				var data = {};
				$scope.UpdateAppointments($routeParams.id, $scope.Appointments)
				$scope.orderDetailsCopy = {};
				angular.copy($scope.orderDetails, $scope.orderDetailsCopy);
				data.OrderDetail = $scope.orderDetailsCopy;


				//date changes for bst dates.
				data.OrderDetail.Sold = window.formatDate(data.OrderDetail.Sold);
				data.OrderDetail.processedDate = window.formatDate(data.OrderDetail.processedDate);
				data.OrderDetail.InstallationDueDate = window.formatDate(data.OrderDetail.InstallationDueDate);
				data.OrderDetail.InitialContactDate = window.formatDate(data.OrderDetail.InitialContactDate);
				data.OrderDetail.SentToSupplier = window.formatDate(data.OrderDetail.SentToSupplier);
				data.OrderDetail.DispatchDate = window.formatDate(data.OrderDetail.DispatchDate);
				data.OrderDetail.SupplierDeliveryDate = window.formatDate(data.OrderDetail.SupplierDeliveryDate);
				data.OrderDetail.JobCompleted = window.formatDate(data.OrderDetail.JobCompleted);
				data.OrderDetail.PaymentContirm = window.formatDate(data.OrderDetail.PaymentContirm);
				data.OrderDetail.GuaranteeIssued = window.formatDate(data.OrderDetail.GuaranteeIssued);
				data.OrderDetail.ReturnVisit = window.formatDate(data.OrderDetail.ReturnVisit);

				$scope.SaveOrderPromise = ($rootScope.onLineFlag) ? SaleOrderService.updateOrder(data) : IpadService.updateSalesOrder(data.OrderDetail);
				$scope.SaveOrderPromise.then(function (output) {
					NotificationService.alert('Order Success', 'Order detail saved successfully.', 'success', {});
					saveGuaranteeLevelForOrder(data.OrderDetail.OrderId);
					if ($scope.orderDetails.ProFormaRequired && $scope.orderDetails.ProFormaSent) {
						NotificationService.alert('Proforma', 'Date set.', 'Info', {});
						$rootScope.$emit("UpdateProformaInfo", {});
					};
					if ($scope.orderDetails.ProFormaRequired && !$scope.orderDetails.ProFormaSent) {
						//NotificationService.alert('Proforma', 'Date not set.', 'Info', {});
						$rootScope.$emit("UpdateProformaInfo", {});
					};
					if (!$scope.orderDetails.ProFormaRequired) {
						//NotificationService.alert('Proforma', 'Cancelled.', 'Info', {});
						$rootScope.$emit("UpdateProformaInfo", {});
					};
					if ($scope.orderDetails.ProFormaRequired) {
						$rootScope.$emit("UpdateProformaInfo", {});
					};
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Order Error', 'There was an error saving the order.', 'error', {});
				});

			};

			function saveGuaranteeLevelForOrder(id) {
				$scope.sortingGuaranteePromise = SaleOrderService.saveGuaranteeLevel({ OrderId: id });

				$scope.sortingGuaranteePromise.then(function (output) {
					if (output) {
						NotificationService.alert('Guarantee Updated', 'The guarantee for this order has been successfully updated.', 'success', {});
						if (window.location.href.indexOf('deposit') > 0)
							$location.path('/sales-order/' + $scope.orderDetails.LeadId);
						else
							$scope.initHrefCheck();
					}
					else
						NotificationService.alert('Guarantee Error', 'There was an error when attempting to sort the guarantee level for this order.', 'error', {});
				});

			}

			$scope.saveEnquiry = function () {
				var data = {
					LeadDetail: $scope.enquiry
				};
				data.LeadDetail.FollowUpCallDue = window.formatDate(data.LeadDetail.FollowUpCallDue);
				data.LeadDetail.FollowUpMailDue = window.formatDate(data.LeadDetail.FollowUpMailDue);
				data.LeadDetail.Issued = window.formatDate(data.LeadDetail.Issued);
				data.LeadDetail.BrochureSent = window.formatDate(data.LeadDetail.BrochureSent);
				data.LeadDetail.Quoted = window.formatDate(data.LeadDetail.Quoted);
				data.LeadDetail.SampleOrder = window.formatDate(data.LeadDetail.SampleOrder);
				if ($scope.AlternativeContactItem.ContactName != null && $scope.AlternativeContactItem.TypeOfContactata != null && $scope.AlternativeContactItem.ContactData != null)
					data.LeadDetail.AlternativeContactItems.push($scope.AlternativeContactItem);

				$scope.SaveEnquiryPromise = ($rootScope.onLineFlag) ? LeadProfileService.updateLead(data) : IpadService.updateEnquiry(data);
				$scope.SaveEnquiryPromise.then(function (output) {
					$scope.output = ($rootScope.onLineFlag) ? output.data.Id : output.Id;
					$scope.enquiry.LeadId = $scope.output;
					$scope.$emit('StartUpdateKPIs');
					$scope.viewEnquiry();
					$scope.AlternativeContactItem = {
						ContactName: null,
						TypeOfContactata: null,
						ContactData: null
					};
					$scope.SampleOrder = {
						Product: null,
						Fabric: null,
						RailType: null,
						RailColour: null,
						Notes: null
					};
					$scope.saveSaleOrder();
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Enquiry Error', 'There was an error saving the Access Details or Ridge Height.', 'error', {});
				});
			}

			$scope.toggleUrgent = function () {
				if ($scope.orderDetails.Urgent == false)
					$scope.orderDetails.Urgent = true;
				else
					$scope.orderDetails.Urgent = false;

				$scope.save();
			};

			$scope.MakeNewAppointment = function (type, customerInfo) {
				if (type == 'Installation' && !$scope.orderDetails.SurveyApptDetails)
					NotificationService.alert('Survey Details', 'You must have surveyed the customer before booking an installation appointment.', 'warning', {});
				else {
					var Data = {
						Type: type == 'Installation' ? 'Installation' : 'Survey',
						ObjectID: customerInfo.SystemId,
						Ref: customerInfo.LeadId,
						sender: $rootScope.User.FullName(),
						Customer: customerInfo.Forename + ' ' + customerInfo.Surname,
						SelectedRecipient: $scope.orderDetails.Fitter.Id
					}

					var modalInstance = $modal.open({
						animation: true,
						size: 'lg',
						templateUrl: 'views/modal-templates/book-appointment.html',
						controller: 'CreateAppointmentModalCtrl',
						resolve: {
							Data: function () {
								return Data;
							}
						}
					});

					modalInstance.result.then(function (output) {
						if (output.type == 'Survey')
							$scope.orderDetails.ChangeStatus = $scope.allStatusList.find(function (item) { return item.Name == 'Survey Booked' }).Id;
						else
							$scope.initHrefCheck();
					});
				}
			};

			$scope.quickSearch = function () {
				$scope.quickSearchPromise = SaleOrderService.quickSearch($scope.RefNumber);
				$scope.quickSearchPromise.then(function (output) {
					$location.path('/sales-order/' + output.data);
					//$scope.init();
				}, function (error) {
					if (error.data != undefined && error.data != null && error.data.Message != undefined && error.data.Message != null && error.data.Message != "")
						NotificationService.alert('Quick Search Error', error.data.Message, 'error', {});
					else
						NotificationService.alert('Quick Search Error', 'There was an error quick searching.', 'error', {});
				});
			};

			$scope.createMemo = function (lead) {
				var modalInstance = openModal('views/modal-templates/add-memo.html', 'SendMemoController', {
					Id: $scope.orderDetails.LeadId,
					Type: "Reminder",
					SentPath: "sales-order",
					SentId: $routeParams.id,
					Name: ($scope.orderDetails.Forename || '') + ' ' + ($scope.orderDetails.Surname || '')
				});

				modalInstance.result.then(function () {
					$scope.$emit('StartUpdateKPIs');
				});
			};

			$scope.openCreateAlertModal = function () {
				var modalInstance = openModal('views/modal-templates/create-alert.html', 'CreateAlertModalController', {
					ObjectId: $routeParams.id,
					DealtWith: false,
					IsDeleted: false,
					UserId: '',
					SentByUser: $rootScope.User.Id
				});

				modalInstance.result.then(function () {
					$scope.$emit('StartUpdateKPIs');
				});
			};

			$scope.openAddNoteModal = function () {
				openModal('views/modal-templates/add-note.html', 'AddNoteController', {
					ObjectId: $scope.orderDetails.OrderId,
					ObjectName: "SaleOrder",
					Note: ""
				});
			};

			$scope.openMemoHistoryModal = function () {
				openModal('views/modal-templates/memo-history.html', "MemoHistoryController", $scope.orderDetails.LeadId);
			};

			$scope.openNoteHistoryModal = function () {
				openModal('views/modal-templates/note-history.html', 'NoteHistoryController', $scope.orderDetails.OrderId);
			};

			$scope.openSendLetterModal = function () {
				var data = {
					ObjectId: $routeParams.id,
					ObjectType: 'sales-order',
					LetterType: 'letter',
					LetterLocation: 'Order',
					ViewLocation: 'Letter'
				};
				var config = {
					LetterType: '',
				};
				openModal('views/modal-templates/send-letter.html', 'SendLetterController', data, config);
			};
			$scope.openSendLetterOuderlineModal = function () {
				var data = {
					ObjectId: $routeParams.id,
					ObjectType: 'sales-order-Line',
					LetterType: 'order line',
					LetterLocation: 'Order',
					ViewLocation: 'Order-Line'
				};
				var config = {
					LetterType: 'OrderLine',
				};
				openModal('views/modal-templates/send-letter.html', 'SendLetterController', data, config);
			};
			$scope.openProductDetailModal = function (data) {
				var modalInstance = openModal('views/modal-templates/ProductDetail.html', 'ProductDetailController', data);

				modalInstance.result.then(function () {
					$scope.initHrefCheck();
				})
			}
			$scope.openConfirmationletterModal = function (data) {
				openModal('views/modal-templates/ConfirmationLetter.html', 'ConfirmationLetterController', data)
			}

			$scope.openActivityLogModal = function (id) {
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: "views/modal-templates/activity-log.html",
					controller: "ActivityLogController",
					resolve: {
						Id: function () {
							return id;
						}
					}
				});
			};

			$scope.openSurveyHistoryModal = function (data) {
				var modalInstance = openModal('views/modal-templates/SurveyHistory.html', 'SurveyHistoryModalController', { SaleOrderId: $scope.orderDetails.OrderId, SurveyInUseId: $scope.orderDetails.SurveyId });

				modalInstance.result.then(function () {
					$scope.initHrefCheck();
				})
			}

			$scope.openSurveyConsole = function () {
				$location.path('/ipad-dashboard/new/survey/' + $scope.orderDetails.LeadId);
			}

			$scope.openInstallationConsole = function () {
				$location.path('/ipad-dashboard/new/install/' + $scope.orderDetails.LeadId);
			}

			$scope.currencyFields = function (inputValue, inputRoute) {
				currency(inputValue, inputRoute);
			}

			function currency(inputValue, inputRoute) {
				if (inputValue == null) {
					return null;
				}
				else {
					if (inputRoute == "Amount") {
						var strCurrency = inputValue.Amount.toString();
					}
					else
						var strCurrency = inputValue.toString();
					if (strCurrency.substring(0, 1) == '+')
						strCurrency = strCurrency.substring(1, strCurrency.length);
					if (strCurrency.substring(0, 1) == '-' && inputRoute != 'Balance')
						NotificationService.alert('Negative Number Detected', 'The number inside ' + inputRoute + ' is negative. Please input a non negative number.', 'error', {});
					else if (strCurrency != '' || strCurrency != 0) {
						if (strCurrency / strCurrency == 1 || strCurrency == 0) {
							if (strCurrency.includes('.')) {
								if (inputValue.length < 3) {
									var cutCurrency = strCurrency;
								}
								else {
									var cutCurrency = strCurrency.slice((strCurrency.length - 3), strCurrency.length);
								}
								if (cutCurrency.substring(0, 1) != '.') {
									if (cutCurrency.substring(1, 2) != '.') {
										if (cutCurrency.substring(2, 3) != '.') {
											NotificationService.alert('Invalid Item', 'Invalid ' + inputRoute + ' detected. Please input a valid amount of currency.', 'error', {});
										}
										else
											strCurrency = strCurrency + '00';
									}
									else if (inputValue.length < 3)
										strCurrency = strCurrency + '00';
									else
										strCurrency = strCurrency + '0';
								}
								else if (inputValue.length < 3)
									strCurrency = '0' + strCurrency + '0';
								else if (strCurrency.length == 3)
									strCurrency = '0' + strCurrency;
							}
							else
								strCurrency = strCurrency + '.00';
						}
						else
							NotificationService.alert('Invalid Item', 'Unexpected values detected inside ' + inputRoute + '. Please input a valid amount of currency.', 'error', {});
					}
					else
						strCurrency = '0.00';


					if (inputRoute == "Order Value")
						$scope.orderDetails.OrderValue = strCurrency;
					else if (inputRoute == "Deposit Amount")
						$scope.orderDetails.DepositAmount = strCurrency;
					else if (inputRoute == "Balance")
						$scope.orderDetails.Balance = strCurrency;
					else if (inputRoute == "Amount") {
						for (var i = 0; i < $scope.orderDetails.Payments.length; i++) {
							if ($scope.orderDetails.Payments[i].Id == inputValue.Id)
								$scope.orderDetails.Payments[i].Amount = strCurrency;
						}
					}
					return strCurrency;
				}
			}


			$scope.balanceRecalculate = function () { //if OrderValue or DepositAmount are modified, Balance must be changed.
				if ($scope.orderDetails.OrderValue == null || $scope.orderDetails.DepositAmount == null)
					return null
				else {
					$scope.orderDetails.Balance = $scope.orderDetails.OrderValue - $scope.orderDetails.DepositAmount;
					currency($scope.orderDetails.Balance, 'Balance');
				}
			}

			$scope.openCreatePaymentModal = function (OrderId, PaymentId, Type) {
				var data = {
					OrderId: OrderId,
					PaymentId: PaymentId || 0,
					type: Type,
					Payment: {
						Deposit: $scope.orderDetails.DepositAmount,
						Balance: $scope.orderDetails.Balance,
						OrderTotal: $scope.orderDetails.OrderValue,
						TotalPayments: $scope.orderDetails.TotalPayments,
						PartPayement90: $scope.orderDetails.PartPayment90,
						PartPayment10: $scope.orderDetails.PartPayment10,
						PaymentMethod: $scope.orderDetails.PaymentMethod
					},
					PickList: $scope.PickList
				};
				var modalInstance = openModal('views/modal-templates/payment.html', 'CreatePaymentController', data);
				modalInstance.result.then(function () {
					$scope.initHrefCheck();
				});
			};

			$scope.removePayment = function (PaymentId) {
				if (confirm("Are you sure that you want to remove this payment?")) {
					$scope.removePaymentPromise = SaleOrderService.removePayment(PaymentId);
					$scope.removePaymentPromise.then(function () {
						$scope.initHrefCheck();
					}, function (error) {
						NotificationService.alert('Payment Error', 'There was an error removing the payment.', 'error', {});
					});
				};
			};

			$scope.openSurveyMaintenanceModal = function (order, MaintenanceId) {
				var FirstName = $scope.orderDetails.Forename || "";
				var LastName = $scope.orderDetails.Surname || "";
				var data = {
					OrderId: order.SystemId,
					MaintenanceId: MaintenanceId || 0,
					Customer: FirstName + ' ' + LastName,
					Ref: order.LeadId
				};
				if (data.MaintenanceId > 0) {
					var modalInstance = openModal('views/modal-templates/maintenance.html', 'SurveyMaintenanceController', data);
					modalInstance.result.then(function () {
						$scope.initHrefCheck();
					});
				}
				else {
					var Closed = [];
					for (var i = 0; i < $scope.allStatusList.length; i++) {
						if ($scope.allStatusList[i].Name.includes('Closed') || $scope.allStatusList[i].Name.includes('Service Call') || $scope.allStatusList[i].Name.includes('Chargeable Service Call') || $scope.allStatusList[i].Name.includes('Maintenance Visit') || $scope.allStatusList[i].Name.includes('Service Phone'))
							Closed.push($scope.allStatusList[i].Id);
					}
					if (!Closed.includes($scope.orderDetails.ChangeStatus))
						NotificationService.alert('Order Is Not Closed or a Service Call.', 'The status of the order you are trying to create a Service/Maintenance for is not closed or set to a service call. Please investigate why.', 'error', {});
					else {
						var MaintenancesNotResolved = false;
						for (var i = 0; i < $scope.orderDetails.Maintenances.length; i++) {
							if ($scope.orderDetails.Maintenances[i].Resolved == null)
								MaintenancesNotResolved = true;
						}
						if (MaintenancesNotResolved == true)
							NotificationService.alert('Order Has Unresolved Maintenances', 'The order you are trying to create a Service/Maintenance has unresolved maintenances. Please investigate why.', 'warning', {});
						var modalInstance = openModal('views/modal-templates/maintenance.html', 'SurveyMaintenanceController', data);
						modalInstance.result.then(function () {
							$scope.initHrefCheck();
						});
					}
				}
			};

			$scope.removeSurveyMaintenance = function (MaintenanceId) {
				if (confirm("Are you sure that you want to remove this maintenance?")) {
					var data = {
						Type: "Maintenance",
						To: new Date(),
						From: new Date(),
						Id: MaintenanceId,
						isDeleted: true,
						FitterId: null
					};
					$scope.RemoveMaintenanceAppointmentPromise = AppointmentService.UpdateAppointmentBooking(data);
					$scope.removeMaintenancePromise = SaleOrderService.removeSurveyMaintenance(MaintenanceId);
					$scope.RemoveMaintenanceAppointmentPromise.then(function () {
						$scope.removeMaintenancePromise.then(function () {
							$scope.initHrefCheck();
						}, function (error) {
							NotificationService.alert('Maintenance Error', 'There was an error removing the maintenance.', 'error', {});
						});
					}, function (error) {
						NotificationService.alert('Maintenance Appointment Error', 'There was an error removing the maintenance appointment.', 'error', {});
					});

				};
			};

			$scope.openRemakesModal = function (OrderId, RemakeId, ReturnVisit) {
				if (ReturnVisit == "0001-01-01T00:00:00")
					ReturnVisit = 0;
				var data = {
					OrderId: OrderId,
					RemakeId: RemakeId || 0,
					Appointment: ReturnVisit || 0,
					Odrerinfo: {
						CustomerName: $scope.orderDetails.Forename + ' ' + $scope.orderDetails.Surname,
						Sender: $rootScope.User.FullName(),
						SelectedRecipient: $scope.orderDetails.Fitter ? $scope.orderDetails.Fitter.Id : null,
					},
					ExternalRef: $routeParams.id
				};
				var modalInstance = openModal('views/modal-templates/remake.html', 'RemakeController', data);
				modalInstance.result.then(function () {
					$scope.initHrefCheck();
				});
			};

			$scope.removeRemake = function (RemakeId, OrderId, isDeleted) {
				var data = {
					Type: "Remake",
					To: new Date(),
					From: new Date(),
					OrderId: OrderId,
					Id: RemakeId,
					isDeleted: isDeleted,
					FitterId: null
				};
				if (confirm("Are you sure that you want to remove this remake?")) {
					$scope.RemoveRemakeAppointmentPromise = AppointmentService.UpdateAppointmentBooking(data);
					$scope.removeRemakePromise = SaleOrderService.removeRemake(RemakeId);
					$scope.removeRemakePromise.then(function () {
						$scope.initHrefCheck();
					}, function (error) {
						NotificationService.alert('Remake Error', 'There was an error removing the remake.', 'error', {});
					});
				};
			};

			$scope.removeFile = function (FileId) {
				if (confirm("Are you sure that you want to remove this file?")) {
					$scope.removeFilePromise = SaleOrderService.removeFile(FileId);
					$scope.removeFilePromise.then(function () {
						$scope.initHrefCheck();
					}, function (error) {
						NotificationService.alert('File Error', 'There was an error removing the file.', 'error', {});
					});
				};
			};

			$scope.openFileLogModal = function () {
				var data = {
					id: $scope.orderDetails.OrderId,
					CurrentProfile: $scope.orderDetails.LeadId,
					RequestArea: "LeadProfile"
				};
				openModal('views/modal-templates/file-log.html', 'FileLogController', data);
			};

			$scope.AddFiles = function () {
				openModal('views/modal-templates/add-file.html', 'AddFile', {
					Id: $scope.orderDetails.OrderId,
					CurrentProfile: $scope.orderDetails.LeadId,
					RequestArea: "LeadProfile"
				});
			};

			$scope.GenerateEmailContract = function (order) {
				if (confirm('This will generate and send a contract to the customer for this order. Please ensure that you only do this if the customer is expecting to receive a new or updated contract. If you are unsure then please check with the Contracts Team. Do you wish to continue?')) {
					//alert(JSON.stringify(order));
					$scope.emailingContractPromise = LetterGenerationService.emailContract({ OrderId: order });
					$scope.emailingContractPromise.then(success => {
						NotificationService.alert('Contract Emailed', 'Contract emailed successfully.', 'success', {});
						$scope.getOrderData($routeParams.id);
					}, err => {
						NotificationService.alert('Contract Email Error', 'There was an error when attempting to email this contract.', 'error', {});
					});
				}
			};

			$scope.CancelOrder = function (order) {
				if (confirm('This will remove the order from this lead permanently. Do you wish to continue?')) {
					//alert(JSON.stringify(order));
					$scope.CancelOrderPromise = SaleOrderService.deleteOrder({ OrderId: order });
					$scope.CancelOrderPromise.then(function (output) {
						// Navigate to the host lead.
						$scope.goToEnquiry();
					}, function (error) {
						$scope.fetchedDataError = true;
						NotificationService.alert('Order Error', 'There was an error cancelling the order.', 'error', {});
					});
				}
			};

			$scope.isSuperAdmin = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Super Administrator').length > 0)
				? true
				: false;

			$scope.PreviewContract = function (order) {

				$scope.previewContractPromise = LetterGenerationService.previewContract({ OrderId: order });
				$scope.previewContractPromise.then(function (output) {
					NotificationService.alert('Contract Preview', 'Contract generated successfully.', 'success', {});
					var blob = new Blob([output.data], { type: 'application/pdf' });
					var blobURL = URL.createObjectURL(blob);
					window.open(blobURL);

					$scope.getOrderData($routeParams.id);
				}, function (error) {
					//alert(JSON.stringify(error));
					console.log(JSON.stringify(error));
					NotificationService.alert('Contract Preview Error', 'There was an error when attempting to preview this contract.', 'error', {});
				});

			};



			function openModal(modalTemplate, Controller, modalData, config) {
				return $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: modalTemplate,
					controller: Controller,
					resolve: {
						data: function () {
							return modalData;
						},
						config: function () {
							return config
						}
					}
				});
			};

			$scope.open = function ($event, variable) {
				$event.preventDefault();
				$event.stopPropagation();

				for (var k in $scope.openPopups) {
					$scope.openPopups[k] = false;
				};
				$scope.openPopups[variable] = true;
			};

			$scope.openLinkedEquiriesModal = function () {
				openModal('views/modal-templates/linked-enquiries.html', 'LinkedEnquiriesController', $scope.orderDetails.LeadId);
			};

			function getNotes() {
				if ($rootScope.onLineFlag) {
					$scope.gettingNotesPromise = EnquiryModalService.getNoteHistory($scope.orderDetails.OrderId);
					$scope.gettingNotesPromise.then(output => {
						$scope.saleOrderNotes = output.data.Notes;
					}, err => {
						NotificationService.alert('Notes Error', 'There was an error retrieving the list of notes for this order.', 'error', {});
					});
				}
			};

			$scope.removeAlternativeContactitem = function (item) {
				$scope.removingAlternativeContactPromise = LeadProfileService.removeAlternativeContactItem({ ContactId: item.Id });
				$scope.removingAlternativeContactPromise.then(function (success) {
					NotificationService.alert('Contact Removed', 'This alternative contact item has been removed.', 'success', {});
					$scope.getOrderData($routeParams.id);
				}, function (err) {
					NotificationService.alert('Contact Removal Error', 'There was an error when attemtping to remove this contact.', 'error', {});
				});
			};

			$scope.updateAlternativeContactitem = function (item) {
				var contact = Object.assign({}, item);
				var modalInstance = openModal('views/modal-templates/update-contact.html', 'UpdateAlternativeContactItemController', {
					Contact: contact
				});

				modalInstance.result.finally(function () {
					$scope.getOrderData($routeParams.id);
				});
			};

			function fetchCloudFileNumber() {
				var data = {
					SynoSid: $rootScope.SynoSid,
					LeadId: $scope.orderDetails.LeadId
				}

				$scope.FetchCloudFileNumberPromise = EnquiryModalService.fetchCloudFileNumber(data);
				$scope.FetchCloudFileNumberPromise.then(function (output) {
					$rootScope.NumberOfFiles = output.data;
				});
			}

			$scope.RemoveAppointment = function (type) {
				if (confirm('Are you sure you want to delete this appointment?')) {
					var id = type == 'Survey' ? $scope.orderDetails.SurveyApptId : $scope.orderDetails.InstallationApptId;
					$scope.updatingAppointmentPromise = AppointmentService.UpdateAppointmentBooking({ Id: id, isDeleted: true });
					$scope.updatingAppointmentPromise.then(success => {
						NotificationService.alert('Appointment Removed', 'This appointment has been successfully removed.', 'success', {});
						$scope.getOrderData($routeParams.id);
					}, err => {
						NotificationService.alert('Appointment Error', 'There was an error updating the associated appointment with this maintenance booking.', 'error', {});
					});
				}
			}

			$scope.GetLeadSummary = function () {
				//alert('V2 tempting to download list of files 2');
				if ($rootScope.SynoSid != '') {
					$scope.template = {};
					//get file from cloud
					var data = {
						Sid: $rootScope.SynoSid,
						CurrentProfile: "",
						SelectedFileName: "LeadSummary",
						SelectedFileType: ".docx",
						LetterLocation: "Static",
						ViewLocation: "Static Files",
					}
					var fileGen = {
						ObjectId: $scope.orderDetails.LeadDBId,
						ObjectType: 'LeadSummary',
						LetterType: 'LeadSummary',
						LetterLocation: "Static",
						ViewLocation: "Static Files",
					}

					$scope.FileDownloadPromice = EnquiryModalService.downloadTemplate(data);
					$scope.FileDownloadPromice.then(function (output) {
						var string = 'data:' + output.data.FileType + ';base64,' + output.data.Base64;
						var byteString = window.atob(string.split(',')[1]);
						var ab = new ArrayBuffer(byteString.length);
						var ia = new Uint8Array(ab);
						for (var i = 0; i < byteString.length; i++) {
							ia[i] = byteString.charCodeAt(i);
						};

						var type = $rootScope.MimeArray.find(arr => arr.ext === output.data.FileType);

						var blob = new Blob([ab], {
							type: type.mime
						});

						// move file to $scope.template
						$scope.template.file = new File([blob], output.data.FileName + output.data.FileType);

						//generate letter
						$scope.LetterPromise = LetterGenerationService.MakeLetter(fileGen, $scope.template.file);
						$scope.LetterPromise.then((output) => {
							$scope.downloadCompleted = true;
							var file = new Blob([output.data])
							saveAs(file, $scope.template.file.name);
						}, (error) => {
							NotificationService.alert('Lead Summary failed', 'There was a error fetching the lead summary', 'error', {});
						})
					})
				}
				else
					NotificationService.alert('Not logged into cloud', 'You are currently not logged into the cloud storage, lead summary failed.', 'error', {});
			}

			// Add event listener for messages from PricingApp to close commission modal
			window.addEventListener('message', function (event) {
				if (event.data === 'closePricingAppModal') {
					$scope.closePricingAppModal();
				}
			}, false);
		}
	])
	.controller('ConfirmationLetterController', ["$scope", "$modalInstance", "data", function ($scope, $modalInstance, data) {
		orderDetails = data.orderDetails;
		OrderDetailLine = data.OrderDetailLine;
		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('ProductDetailController', ["$rootScope", "$scope", "SaleOrderService", "$modalInstance", "NotificationService", "IpadService", "data", function ($rootScope, $scope, SaleOrderService, $modalInstance, NotificationService, IpadService, data) {
		$scope.Item = data.item;
		$scope.OrderLineList = data.list;
		$scope.Picklist = data.PickList;
		var CustomPickListId = 0;
		var CustomPickList = $scope.Picklist.find(picklist => picklist.PickListEntry == 'Custom' && picklist.PickListName == 'ddmRailColour');
		if (CustomPickList)
			CustomPickListId = CustomPickList.Id;

		$scope.$watch('updateItem.ProductId', () => {
			if ($scope.updateItem)
				$scope.updateItem.Product = $scope.Picklist.find(pl => pl.Id == $scope.updateItem.ProductId);
		});

		$scope.$watch('updateItem.Rails', () => {
			if ($scope.updateItem)
				$scope.updateItem.EndCapColour = $scope.updateItem.Rails;
			//if ($scope.updateItem.Rails == CustomPickListId)
			//	$scope.unlockCustomRails = true;
			//else
			$scope.unlockCustomRails = false;
		});

		if (data.item != null) {
			//var lineId = 9059622		
			$scope.updateItem = angular.copy($scope.OrderLineList.find(i => i.LineId === data.item.LineId));
			if ($scope.updateItem) {
				$scope.updateItem.ProductId = $scope.updateItem.Product ? $scope.updateItem.Product.Id : null;
				$scope.updateItem.EndCapColour = $scope.updateItem.Rails;
				$scope.localIndex = $scope.OrderLineList.indexOf($scope.updateItem);
			}
			//if ($scope.updateItem.Rails == CustomPickListId)
			//	$scope.unlockCustomRails = true;
			//else
			$scope.$unlockCustomRails = false;
		};

		$scope.next = function (item) {
			if ($scope.localIndex < $scope.OrderLineList.length - 1) {
				$scope.localIndex++;
				$scope.updateItem = $scope.OrderLineList[$scope.localIndex];
				$scope.save(item, false);
			};
		};

		$scope.back = function (item) {
			if ($scope.localIndex > 0) {
				$scope.localIndex--;
				$scope.updateItem = $scope.OrderLineList[$scope.localIndex];
				$scope.save(item, false);
			};
		};

		$scope.save = function (item, close) {
			if ($rootScope.onLineFlag) { item.Product = item.Product.Id; }
			$scope.UpdatingOrderLinePromise = ($rootScope.onLineFlag) ? SaleOrderService.updateOrderLine(item) : IpadService.updateOrderline(item);
			$scope.UpdatingOrderLinePromise.then(success => {
				NotificationService.alert('Update Success', 'This order line has been successfully updated.', 'success', {});
				item.Product = $scope.Picklist.find(pl => pl.Id == item.Product);
				if (close)
					$modalInstance.close();
			}, err => {
				NotificationService.alert('Update Error', 'There was an error when attemtping to update this order line.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('QuoteLinesController', ["$rootScope", "$scope", "$modal", "$modalInstance", "NotificationService", "IpadService", "data", "QuoteService", "$filter", "PricingAppService", function ($rootScope, $scope, $modal, $modalInstance, NotificationService, IpadService, data, QuoteService, $filter, PricingAppService) {

		var init = function () {
			$scope.newLine = {};
			$scope.total = 0;
			$scope.LeadId = data.leadId;
			$scope.Item = data.item;
			$scope.OrderLineList = data.list;
			$scope.AddOnList = data.addons;
			$scope.AdditionalChargesList = data.charges;
			$scope.ModifiersList = data.modifiers;
			$scope.Picklist = data.PickList;
			$scope.enquiry = data.enquiry;

			$scope.isUserPricingApp = ($rootScope.User.Roles != undefined && $rootScope.User.Roles.filter(r => r.Name == 'Pricing').length > 0) ? true : false;
			$scope.quotePricingAppCode = data.list[0] && data.list[0].FabricDetail ? data.list[0].FabricDetail : '';

			$scope.showEditQuoteButton = false;
			$scope.isUserOrQuotePricingApp = false;

			// Toggle "PricingApp" features if quote from PricingApp or PricingApp user role
			if ($scope.quotePricingAppCode === 'P' || $scope.isUserPricingApp) {		
				$scope.isUserOrQuotePricingApp = true;
			}
			if ($scope.isUserPricingApp && $scope.quotePricingAppCode === 'P') {
				$scope.showEditQuoteButton = true;
			}

		}
		init();

		setQuotedTotal(data.list, data.addons, data.charges, data.modifiers, $scope.Item.Option);
		var CustomPickListId = 0;
		var CustomPickList = $scope.Picklist.find(picklist => picklist.PickListEntry == 'Custom' && picklist.PickListName == 'ddmRailColour');
		if (CustomPickList)
			CustomPickListId = CustomPickList.Id;

		$scope.$watch('updateItem.ProductId', () => {
			if ($scope.updateItem)
				$scope.updateItem.Product = $scope.Picklist.find(pl => pl.Id == $scope.updateItem.ProductId);
		});

		$scope.$watch('updateItem.Rails', () => {
			if ($scope.updateItem)
				$scope.updateItem.EndCapColour = $scope.updateItem.Rails;
			$scope.unlockCustomRails = false;
		});

		if (data.item != null) {
			$scope.updateItem = angular.copy($scope.OrderLineList.find(i => i.LineId === data.item.LineId));
			if ($scope.updateItem) {
				$scope.updateItem.ProductId = $scope.updateItem.Product ? $scope.updateItem.Product.Id : null;
				$scope.updateItem.EndCapColour = $scope.updateItem.Rails;
				$scope.localIndex = $scope.OrderLineList.indexOf($scope.updateItem);
			}
			$scope.$unlockCustomRails = false;
		};


		$scope.addQuoteLine = function (newline) {
			newline.Option = $scope.Item.Option;
			newline.LeadId = $scope.LeadId;
			$scope.creatingNewQuoteLinePromise = QuoteService.newQuoteLine(newline);
			$scope.creatingNewQuoteLinePromise.then(function () {
				$scope.newLine = {};
				getQuoteLines(true, true);
			});

		};

		function getQuoteLines(created, sortGuarantee) {

			$scope.getQuoteLinePromise = QuoteService.getQuoteLines($scope.LeadId);
			$scope.getQuoteLinePromise.then(function (output) {
				$scope.LeadProfileQuoteLine = ($rootScope.onLineFlag) ? output.data.Quotelines : output;
				$scope.LeadProfileQuoteLine.map((m) => {
					m.Product = $scope.Picklist.find((x) => {
						return (x.Id == m.Product)
					});
					return m;
				});
				if ($scope.LeadProfileQuoteLine.length > 0) {
					setQuotedTotal($scope.LeadProfileQuoteLine, $scope.AddOnList, $scope.AdditionalChargesList, $scope.ModifiersList, $scope.Item.Option);
					$scope.OrderLineList = $scope.LeadProfileQuoteLine;
				}
			}, function (error) {
				NotificationService.alert('Quote Line Error', 'There was an error getting quote lines.', 'error', {});
			});
		};

		//$scope.changeQuotedTotal = function (lines, option) {
		//	setQuotedTotal(lines, option);
		//}

		function setQuotedTotal(lines, addons, charges, modifiers, option) {

			$scope.total = getQuotedTotal(lines, addons, charges, modifiers, option);
			if (option == 1) {
				$scope.enquiry.TotalQuotedPrice1 = getQuotedTotal(lines, addons, charges, modifiers, option);
			}
			else if (option == 2) {
				$scope.enquiry.TotalQuotedPrice2 = getQuotedTotal(lines, addons, charges, modifiers, option);
			}
			else if (option == 3) {
				$scope.enquiry.TotalQuotedPrice3 = getQuotedTotal(lines, addons, charges, modifiers, option);
			}
		}

		function getQuotedTotal(lines, addons, charges, modifiers, option) {
			var total = 0;

			// Quote Lines
			for (var i = 0; i < lines.length; i++) {
				var line = lines[i];
				if (line.Option == option) {
					total += parseFloat(line.cost);
				}
			}

			// Add Ons
			for (var i = 0; i < addons.length; i++) {
				var addon = addons[i];
				if (addon.QuoteOption == option) {
					total += parseFloat(addon.Qty) * parseFloat(addon.Value);
				}
			}

			// Additional Charges
			for (var i = 0; i < charges.length; i++) {
				var charge = charges[i];
				if (charge.QuoteOption == option) {
					total += parseFloat(charge.Value);
				}
			}

			// Modifiers
			let modifierTotal = total;
			let modifier = modifiers.find(mod => mod.QuoteOption == option);
			if (modifier) {
				if (parseFloat(modifier.MarketingDiscount) > 0)
					modifierTotal -= parseFloat(total) * (parseFloat(modifier.MarketingDiscount) / 100);
				if (parseFloat(modifier.SalesDiscount) > 0)
					modifierTotal -= parseFloat(total) * (parseFloat(modifier.SalesDiscount) / 100);
				if (parseFloat(modifier.LeadProviderCommissionDiscount) > 0)
					modifierTotal += parseFloat(total) * (parseFloat(modifier.LeadProviderCommissionDiscount) / 100);
			}
			
			return $filter('number')(modifierTotal, 2);
		}

		$scope.saveQuoteLines = function (items) {
			items.forEach(saveQuoteLine);
			NotificationService.alert('Update Success', 'These quote lines have been successfully updated.', 'success', {});
			$modalInstance.close();
			//$scope.save();
		};

		function saveQuoteLine(item) {
			if (item.Product != undefined) {
				if ($rootScope.onLineFlag) { item.Product = item.Product.Id; }
				$scope.UpdatingQuoteLinePromise = QuoteService.updateQuoteLine(item);
				$scope.UpdatingQuoteLinePromise.then(success => {
					item.Product = $scope.Picklist.find(pl => pl.Id == item.Product);
				}, err => {
					NotificationService.alert('Update Error', 'There was an error when attemtping to update these quote lines.', 'error', {});
				});
			}
		}

		let pricingAppModalInstance;

		$scope.redirectToPricingApp = function (id, type, option) {
			// Close the oringal quote modal
			$modalInstance.close();
			PricingAppService.getConfigValue('PortalUrl').then(function (response) {
				PricingAppService.getConfigValue('PortalCredentials').then(function (response2) {
					let portalUrl = response.data;
					let portalCredentials = response2.data;
					var data = { id, type, portalUrl, option, portalCredentials }
					pricingAppModalInstance = openPricingAppModal('views/modal-templates/iframe.html', 'IFrameController', data);
					pricingAppModalInstance.result.finally(function () {
						// Refresh Enquiry after the modal closes - Emit an event to notify that the modal has closed
						$rootScope.$emit('pricingAppModalClosed');
					});
				});
			});
		};

		$scope.closePricingAppModal = function () {
			if (pricingAppModalInstance) {
				pricingAppModalInstance.close();
			}
		};

		function openPricingAppModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				templateUrl: modalTemplate,
				controller: Controller,
				windowClass: 'xlarge-Modal',
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};

		// Add event listener for messages from PricingApp to close commission modal
		window.addEventListener('message', function (event) {
			if (event.data === 'closePricingAppModal') {
				$scope.closePricingAppModal();
			}
		}, false);
	}])

	.controller('PricingLinesController', ["$rootScope", "$scope", "$modalInstance", "NotificationService", "IpadService", "data", "QuoteService", function ($rootScope, $scope, $modalInstance, NotificationService, IpadService, data, QuoteService) {

		var init = function () {
			$scope.newLine = {};
			$scope.total = 0;
			$scope.LeadId = data.leadId;
			$scope.Item = data.item;
			$scope.OrderLineList = data.list;
			$scope.Picklist = data.PickList;
			$scope.enquiry = data.enquiry;
		}
		init();

		setQuotedTotal(data.list, $scope.Item.Option);
		var CustomPickListId = 0;
		var CustomPickList = $scope.Picklist.find(picklist => picklist.PickListEntry == 'Custom' && picklist.PickListName == 'ddmRailColour');
		if (CustomPickList)
			CustomPickListId = CustomPickList.Id;

		$scope.$watch('updateItem.ProductId', () => {
			if ($scope.updateItem)
				$scope.updateItem.Product = $scope.Picklist.find(pl => pl.Id == $scope.updateItem.ProductId);
		});

		$scope.$watch('updateItem.Rails', () => {
			if ($scope.updateItem)
				$scope.updateItem.EndCapColour = $scope.updateItem.Rails;
			$scope.unlockCustomRails = false;
		});

		if (data.item != null) {
			$scope.updateItem = angular.copy($scope.OrderLineList.find(i => i.LineId === data.item.LineId));
			if ($scope.updateItem) {
				$scope.updateItem.ProductId = $scope.updateItem.Product ? $scope.updateItem.Product.Id : null;
				$scope.updateItem.EndCapColour = $scope.updateItem.Rails;
				$scope.localIndex = $scope.OrderLineList.indexOf($scope.updateItem);
			}
			$scope.$unlockCustomRails = false;
		};


		$scope.addQuoteLine = function (newline) {
			newline.Option = $scope.Item.Option;
			newline.LeadId = $scope.LeadId;
			$scope.creatingNewQuoteLinePromise = QuoteService.newQuoteLine(newline);
			$scope.creatingNewQuoteLinePromise.then(function () {
				$scope.newLine = {};
				getQuoteLines(true, true);
			});

		};

		function getQuoteLines(created, sortGuarantee) {

			$scope.getQuoteLinePromise = QuoteService.getQuoteLines($scope.LeadId);
			$scope.getQuoteLinePromise.then(function (output) {
				$scope.LeadProfileQuoteLine = ($rootScope.onLineFlag) ? output.data.Quotelines : output;
				$scope.LeadProfileQuoteLine.map((m) => {
					m.Product = $scope.Picklist.find((x) => {
						return (x.Id == m.Product)
					});
					return m;
				});
				if ($scope.LeadProfileQuoteLine.length > 0) {
					setQuotedTotal($scope.LeadProfileQuoteLine, $scope.Item.Option);
					$scope.OrderLineList = $scope.LeadProfileQuoteLine;
				}
			}, function (error) {
				NotificationService.alert('Quote Line Error', 'There was an error getting quote lines.', 'error', {});
			});
		};

		//$scope.changeQuotedTotal = function (lines, option) {
		//	setQuotedTotal(lines, option);
		//}

		function setQuotedTotal(lines, option) {

			$scope.total = getQuotedTotal(lines, option);
			if (option == 1) {
				$scope.enquiry.TotalQuotedPrice1 = getQuotedTotal(lines, option);
			}
			else if (option == 2) {
				$scope.enquiry.TotalQuotedPrice2 = getQuotedTotal(lines, option);
			}
			else if (option == 3) {
				$scope.enquiry.TotalQuotedPrice3 = getQuotedTotal(lines, option);
			}
		}

		function getQuotedTotal(lines, option) {
			var total = 0;
			for (var i = 0; i < lines.length; i++) {
				var line = lines[i];
				if (line.Option == option) {
					total += (line.cost);
				}
			}
			return total;
		}

		$scope.saveQuoteLines = function (items) {
			items.forEach(saveQuoteLine);
			NotificationService.alert('Update Success', 'These quote lines have been successfully updated.', 'success', {});
			$modalInstance.close();
			//$scope.save();
		};

		function saveQuoteLine(item) {
			if (item.Product != undefined) {
				if ($rootScope.onLineFlag) { item.Product = item.Product.Id; }
				$scope.UpdatingQuoteLinePromise = QuoteService.updateQuoteLine(item);
				$scope.UpdatingQuoteLinePromise.then(success => {
					item.Product = $scope.Picklist.find(pl => pl.Id == item.Product);
				}, err => {
					NotificationService.alert('Update Error', 'There was an error when attemtping to update these quote lines.', 'error', {});
				});
			}
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('CreateSaleOrderController', ["$rootScope", "$scope", "$routeParams", "$filter", "LeadProfileService", "SaleOrderService", "PickListService", "NotificationService", "StaffService", "$location", "ParamService", "IpadService", "EnquiryModalService", "QuoteService", "$modal", "PricingAppService", function ($rootScope, $scope, $routeParams, $filter, LeadProfileService, SaleOrderService, PickListService, NotificationService, StaffService, $location, ParamService, IpadService, EnquiryModalService, QuoteService, $modal, PricingAppService) {
			$scope.init = function () {
				$scope.Order = {
					LeadId: $routeParams.id
				};
				$scope.UnhideCustomRails = false;
				$scope.GetLeadProfilePromise = ($rootScope.onLineFlag) ? LeadProfileService.getEnquiryById($scope.Order.LeadId) : IpadService.getLeadProfile($scope.Order.LeadId);
				$scope.GetLeadProfilePromise.then(function (output) {
					$scope.enquiry = ($rootScope.onLineFlag) ? output.data.LeadDetail : output;
					$scope.Order.SalesPerson = $scope.enquiry.SalesPerson;
					$scope.Order.SoldDate = Date.now();
					$scope.Order.OrderValue = $scope.enquiry.QuoteRecOption1 ? $scope.enquiry.TotalQuotedPrice1 : $scope.enquiry.QuoteRecOption2 ? $scope.enquiry.TotalQuotedPrice2 : $scope.enquiry.QuoteRecOption3 ? $scope.enquiry.TotalQuotedPrice3 : 0;				
					return getQuoteLines();
				})
				.then(function () {
					// Now that getQuoteLines() is done,
					// $scope.LeadProfileQuoteLine is populated.
					// You can safely access the first item’s FabricDetail here.
					if ($scope.LeadProfileQuoteLine && $scope.LeadProfileQuoteLine.length > 0) {
						//console.log('FabricDetail:', $scope.LeadProfileQuoteLine[0].FabricDetail);
						if ($scope.LeadProfileQuoteLine[0].FabricDetail === 'P') {
							// Pricing App
							$scope.pricingAppOrder = true;
							$scope.Order.DepositAmount = $scope.Order.OrderValue * ($scope.enquiry.DepositPercent / 100);
							$scope.Order.Balance = $scope.Order.OrderValue - $scope.Order.DepositAmount;
						}
					}
				})
				.catch(function (error) {
					// Handle errors from either promise
					console.log('Error:', error);
				});

				$scope.Orderlines = [];
				$scope.filteredPaymentTypes = [];
				$scope.Today = new Date();
				$scope.openPopups = {};
				$scope.GetAllStaffPromise = ($rootScope.onLineFlag) ? StaffService.getAllStaff() : IpadService.getAllStaff();
				$scope.GetAllStaffPromise.then(function (output) {
					$scope.allStaffList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
					$scope.reminderContractsUsers = $scope.allStaffList.filter(staff => staff.Roles.filter(r => r.Name == 'Contracts' || r.Name == 'Factory').length > 0);
					if ($scope.Order.SalesPerson != undefined || $scope.Order.SalesPerson != null) {
						$scope.SalesAssigned = true;
						var staff = $scope.allStaffList.find(s => s.Id == $scope.Order.SalesPerson);
						if (staff != undefined) {
							$scope.Order.SalesPersonFirstName = staff.FirstName;
							$scope.Order.SalesPersonLastName = staff.LastName;
						}
						/* for (var i = 0; i < $scope.allStaffList.length; i++) {
							if (output.data.StaffList[i].Id == $scope.Order.SalesPerson) {
								$scope.Order.SalesPersonFirstName = $scope.allStaffList[i].FirstName;
								$scope.Order.SalesPersonLastName = $scope.allStaffList[i].LastName;
							}
						} */
					}
				}, function (error) {
					NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
				});

				//Get All picklist
				$scope.CollectionPromise = ($rootScope.onLineFlag) ? PickListService.getPickListByPickListName([
					'ddmContractTerm',
					'ddmFactoryStatus',
					'ddmProduct',
					'ddmProductType',
					'ddmRailColour',
					'ddmFabric',
					'ddmPaymentTypes',
					'ddmOperation',
					'ddmContractType',
					'ddmHardwareType',
					'ddmMicro-CableColour',
					'ddmBraidColour',
					'ddmHandleColour',
					'ddmRemoteColour'
				]) : IpadService.getPicklist();
				$scope.CollectionPromise.then(function (output) {
					$scope.picklist = ($rootScope.onLineFlag) ? output.data.PickListEntries : output;
					$scope.CustomPickListId = 0;
					var CustomPickList = $scope.picklist.find(Picklist => Picklist.PickListEntry == 'Custom' && Picklist.PickListName == 'ddmRailColour');
					if (CustomPickList)
						$scope.CustomPickListId = CustomPickList.Id;

					// Check against payment types for ones set not to show in portal
					var paymentTypes = $filter('picklistFilter')($scope.picklist, 'ddmPaymentTypes');
					$scope.filteredPaymentTypes = [];
					paymentTypes.forEach(function (arrayItem) {
						if (arrayItem.ShowInPortal == true) {
							$scope.filteredPaymentTypes.push(arrayItem);
						}
					});
				}, function (error) { });

				$scope.getParamByEntityAndFieldPromise = ParamService.getParamByEntityAndField("SaleOrder", "PaymentType");
				$scope.getParamByEntityAndFieldPromise.then(function (output) {
					$scope.PaymentParams = output.data.Params;
				}, function (error) {
					NotificationService.alert('Params\' Not Retrieved', 'The requested parameter were not received.', 'error', {});
				});

				$scope.viewEnquiry();
			};

			let pricingAppModalInstance;

			$scope.redirectToPricingApp = function (id, type) {
				PricingAppService.getConfigValue('PortalUrl').then(function (response) {
					PricingAppService.getConfigValue('PortalCredentials').then(function (response2) {
						let portalUrl = response.data;
						let portalCredentials = response2.data;
						var data = { id, type, portalUrl, portalCredentials }
						pricingAppModalInstance = openPricingAppModal('views/modal-templates/iframe.html', 'IFrameController', data);
						pricingAppModalInstance.result.finally(function () {
							$scope.viewEnquiry(); // Refresh the Enquiry after the modal closes
						});
					});
				});
			};

			function openPricingAppModal(modalTemplate, Controller, modalData, config) {
				return $modal.open({
					animation: true,
					templateUrl: modalTemplate,
					controller: Controller,
					windowClass: 'xlarge-Modal',
					resolve: {
						data: function () {
							return modalData;
						},
						Id: function () {
							return modalData;
						},
						config: function () {
							return config
						}
					}
				});
			};

			$scope.viewEnquiry = function () {
				$scope.GetEnquiryPromise = ($rootScope.onLineFlag) ? LeadProfileService.getEnquiryById($routeParams.id) : IpadService.getLeadProfile($routeParams.Id);
				$scope.GetEnquiryPromise.then(function (output) {
					$scope.Order.SalesPerson = ($rootScope.onLineFlag) ? output.data.LeadDetail.SalesPerson : output.SalesPerson;
					$scope.enquiry = ($rootScope.onLineFlag) ? output.data.LeadDetail : output;
					if ($scope.enquiry.OrderId > 0)
						$location.path('/sales-order/' + $scope.enquiry.OrderId);
				}, function (error) {
					$scope.fetchedDataError = true;
					NotificationService.alert('Enquiry Error', 'There was an error retriving the enquiry.', 'error', {});
				});
			};

			function getQuoteLines() {
				$scope.Orderlines = [];
				$scope.getQuoteLinePromise = QuoteService.getQuoteLines($scope.enquiry.SystemId);
				return $scope.getQuoteLinePromise.then(function (output) {

					$scope.LeadProfileQuoteLine = output.data.Quotelines;

					$scope.LeadProfileQuoteLine.map((m) => {
						m.Product = $scope.picklist.find((x) => {
							return (x.Id == m.Product)
						});
						return m;
					});
					if ($scope.LeadProfileQuoteLine.length > 0) {
						//	setQuotedTotal($scope.LeadProfileQuoteLine, $scope.Item.Option);

						// Loop through each Quote and and only copy ones where Rec. Option is ticked
						$scope.LeadProfileQuoteLine.forEach((line) => {

							line.QuoteLineId = line.LineId;

							if (line.Option == 1 && $scope.enquiry.QuoteRecOption1 && line.qty > 0) {
								$scope.Orderlines.push(line);
							}
							else if (line.Option == 2 && $scope.enquiry.QuoteRecOption2 && line.qty > 0) {
								$scope.Orderlines.push(line);
							}
							else if (line.Option == 3 && $scope.enquiry.QuoteRecOption3 && line.qty > 0) {
								$scope.Orderlines.push(line);
							}
						});

						//$scope.Orderlines = $scope.LeadProfileQuoteLine.filter(r => r.qty > 0);
					}
				}, function (error) {
					NotificationService.alert('Quote Line Error', 'There was an error getting quote lines.', 'error', {});
				});
			};

			$scope.OrderlinesSaved = 0;
			$scope.save = function () {
				if (confirm('Are you sure you want to create this order?')) {
					var SalesPerson = {
						Id: $scope.Order.SalesPerson,
						FirstName: $scope.Order.SalesPersonFirstName,
						LastName: $scope.Order.SalesPersonLastName,
						Email: '',
						DateCreate: new moment()
					};
					$scope.Order.Salesperson = SalesPerson;

					$scope.Order.ContractGuaranteeNotes = $scope.enquiry.GuaranteeNotes;
					$scope.Order.ContractColourNotes = $scope.enquiry.ColourNotes;

					var data = {
						OrderDetail: $scope.Order
					};
					data.OrderDetail.Sold = $scope.Order.SoldDate;
					$scope.CreateOrderPromise = ($rootScope.onLineFlag) ? SaleOrderService.updateOrder(data) : IpadService.updateSalesOrder(data);
					$scope.CreateOrderPromise.then(function (output) {
						$scope.Orderlines.forEach((line) => {
							saveOrderLine(line, output.data.OrderDetail.OrderId, output.data.OrderDetail.LeadId);
						})
						saveGuaranteeLevelForOrder(output.data.OrderDetail.OrderId);
					}, function (error) {
						$scope.fetchedDataError = true;
						NotificationService.alert('Order Error', 'There was an error creating order.', 'error', {});
					});



				}
			};
			// orderlines
			// needs a review
			function saveOrderLine(newline, OrderId, ExternalRef) {
				newline.SalesOrder = OrderId,
					$scope.creatingNewOrderLinePromise = ($rootScope.onLineFlag) ? SaleOrderService.newOrderLineFromQuote(newline) : IpadService.updateOrderline(newline);
				$scope.creatingNewOrderLinePromise.then(function () {
					$scope.OrderlinesSaved++;
				});
			};

			function saveGuaranteeLevelForOrder(id) {
				$scope.sortingGuaranteePromise = SaleOrderService.saveGuaranteeLevel({ OrderId: id });
				$scope.sortingGuaranteePromise.then(function (output) {
					if (!output)
						NotificationService.alert('Guarantee Error', 'There was an error when attempting to sort the guarantee level for this order.', 'error', {});

					if ($rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0) {
						$location.path('/lead-details/' + $scope.Order.LeadId + '/deposit'); //Lee wants sales to redirect here as they will need to attach files to the lead.
						NotificationService.alert('Order Created', '', 'success', {});
					} else
						$location.path('/sales-order/' + $routeParams.id + "/deposit");
				});
			}

			$scope.addline = function (line) {
				if (line.Rails == $scope.CustomPickListId)
					$scope.UnhideCustomRails = true;
				$scope.Orderlines.push(line);
				$scope.newLine = {
					//Product: $scope.Orderlines[$scope.Orderlines.length - 1].Product,
					//ProductType: $scope.Orderlines[$scope.Orderlines.length - 1].ProductType,
					//Rails: $scope.Orderlines[$scope.Orderlines.length - 1].Rails,
					//CustomRailColour: $scope.Orderlines[$scope.Orderlines.length - 1].CustomRailColour,
					//Fabric: $scope.Orderlines[$scope.Orderlines.length - 1].Fabric,
					//Electric: $scope.Orderlines[$scope.Orderlines.length - 1].Electric,
					//SolarLining: $scope.Orderlines[$scope.Orderlines.length - 1].SolarLining
				};
			}

			$scope.updateBalance = function () {
				if ($scope.Order.DepositAmount >= 0 && $scope.Order.OrderValue >= 0)
					$scope.Order.Balance = $scope.Order.OrderValue - $scope.Order.DepositAmount;
				else if ($scope.Order.OrderValue >= 0)
					$scope.Order.Balance = $scope.Order.OrderValue;
			}

			//opening and closing of datepickers
			$scope.open = function ($event, variable) {
				$event.preventDefault();
				$event.stopPropagation();

				for (var k in $scope.openPopups) {
					$scope.openPopups[k] = false;
				}
				$scope.openPopups[variable] = true;
			};

			$scope.revealCustomRails = function (rails) {
				if (rails == $scope.CustomPickListId)
					$scope.UnhideCustomRails = true;
			};

			$scope.openQuoteOptionsModal = function (id, type) {
				var data = { id, type }
				openModal('views/modal-templates/quote-options.html', 'QuoteOptionsController', data);
			};

			//modal functions
			function openModal(modalTemplate, Controller, modalData, config) {
				return $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: modalTemplate,
					controller: Controller,
					resolve: {
						data: function () {
							return modalData;
						},
						Id: function () {
							return modalData;
						},
						config: function () {
							return config
						}
					}
				});
			};

			$scope.closePricingAppModal = function () {
				if (pricingAppModalInstance) {
					pricingAppModalInstance.close();
				}

				// Reload the iframe
				const iframe = document.getElementById('pricingcalculatorsummaryiframe');
				if (iframe) {
					// Option A: Simply reassign the same src to force a reload
					iframe.src = iframe.src;

					// Option B: Or explicitly call reload on the content window (if cross-domain issues do not arise)
					// iframe.contentWindow.location.reload();
				}
			};

			// Event Listeners from Pricing App
			window.addEventListener('message', function (event) {
				// listener to close modal
				if (event.data === 'closePricingAppModal') {
					$scope.closePricingAppModal();
				}

				// listener dynamically adjust the height of the iframe
				if (event.data && event.data.iframeHeight) {
					const iframe = document.getElementById('pricingcalculatorsummaryiframe');
					if (iframe) {
						iframe.style.height = event.data.iframeHeight + 'px';
					}
				}
			}, false);

		}])
